import React, { useState, useEffect } from 'react';
import DefaultHeader from '../DefaultHeader';
import EventCard from '../EventCard';
import SimpleEventsService from '../../services/simpleEvents.service';
import ComplexEventsService from '../../services/complexEvents.service';
import { useUser } from '../UserProvider';

const UpcomingEvents: React.FC<[]> = () => {
  const { user, editorMode } = useUser();

  // getUpcoming
  const [upcomingEvents, setUpcomingEvents] = useState(null);

  useEffect(() => {
    getUpcomingEvents();
  }, []);

  useEffect(() => {
    getUpcomingEvents(editorMode, user.email);
  }, [user, editorMode]);

  const getUpcomingEvents = async (all?, email?) => {
    const simpleEvents = await SimpleEventsService.getUpcomingEvents(all, email);
    const complexEvents = await ComplexEventsService.getUpcomingEvents(all, email);

    const unsortedEvents = [...(simpleEvents || []), ...(complexEvents || [])];

    const events = unsortedEvents.sort(
      (a, b) => b.start_date.format('YYYYMMDD') - a.start_date.format('YYYYMMDD')
    );

    setUpcomingEvents(events);
  };

  return (
    <div>
      <DefaultHeader title={'Kommende Veranstaltungen'} />

      <div className="section">
        {upcomingEvents && upcomingEvents.length > 0 ? (
          <div className="container">
            <div className="row">
              {upcomingEvents.map((event) => (
                <div key={event.id} className="col-12 col-md-6 col-lg-4">
                  <EventCard event={event} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="container">
            <h3>Derzeit sind keine Veranstaltungen geplant.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
