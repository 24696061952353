import axios from 'axios';
import { Moment } from 'moment-timezone';
import TimeService from './time.service';
import { EventStatus, SessionStatus } from '../enums/status.enum';
import {
  IComplexEvent,
  IComplexEventTalk,
  IComplexEventSession,
} from '../interfaces/complexEvent.interface';
import labelColor from '../utils/labelColor';
import objectToArray from '../utils/objectToArray';
import { SessionType } from '../enums/sessionType.enum';

interface MyError {
  error: any;
}

class ComplexEventsService {
  private static instance: ComplexEventsService;
  private apiUrl: string = process.env.REACT_APP_API_URL;

  private config: any = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  static getInstance(): ComplexEventsService {
    if (!ComplexEventsService.instance) {
      ComplexEventsService.instance = new ComplexEventsService();
    }

    return ComplexEventsService.instance;
  }

  async getEvents(): Promise<IComplexEvent[] | MyError> {
    try {
      const res = await axios.get(`${this.apiUrl}/items/complex_events?fields=*.*`, this.config);
      const events = res.data.data.map((event) => this.preprocessEvent(event));
      return events;
    } catch (error) {
      return {
        error: error,
      };
    }
  }

  async getEventBySlug(slug: string): Promise<IComplexEvent | any> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/complex_events?fields=*.*&filter[slug][eq]=${slug}`,
        this.config
      );
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data.map((event) => this.preprocessEvent(event));

      return events[0];
    } catch (error) {
      return {
        error: error,
      };
    }
  }

  async getTalks(): Promise<IComplexEventTalk[] | MyError> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/complex_event_talks?fields=*.*.*,speakers.speaker_id.*.*`,
        this.config
      );
      const talks = res.data.data;
      return talks;
    } catch (error) {
      return {
        error: error,
      };
    }
  }

  async getTalksForEvent(id: number): Promise<IComplexEventTalk[] | MyError> {
    if (id) {
      try {
        const res = await axios.get(
          `${this.apiUrl}/items/complex_event_talks?fields=*.*.*,speakers.speaker_id.*.*&filter[event.id][eq]=${id}`,
          this.config
        );

        const talks = res.data.data.map((talk) => this.preprocessTalk(talk));

        return talks;
      } catch (error) {
        return {
          error: error,
        };
      }
    }
  }

  async getSessions(): Promise<IComplexEventSession[] | MyError> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/complex_event_sessions?fields=*.*.*`,
        this.config
      );

      const sessions = res.data.data
        .map((session) => this.preprocessSession(session))
        .sort((a, b) => a.starts_at.valueOf() - b.starts_at.valueOf());

      return sessions;
    } catch (error) {
      return {
        error: error,
      };
    }
  }

  async getSessionsForTalk(id: number): Promise<IComplexEventSession[] | MyError> {
    if (id) {
      try {
        const res = await axios.get(
          `${this.apiUrl}/items/complex_event_sessions?fields=*.*.*.*.*&filter[complex_event_talk.id][eq]=${id}`,
          this.config
        );

        const sessions = res.data.data
          .map((session) => this.preprocessSession(session))
          .sort((a, b) => a.starts_at.valueOf() - b.starts_at.valueOf());

        return sessions;
      } catch (error) {
        return {
          error: error,
        };
      }
    }
  }

  // for the upcoming page
  public async getUpcomingEvents(all?: boolean, email?: string): Promise<IComplexEvent[] | any> {
    try {
      let res = [];
      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(
          `${this.apiUrl}/custom/events/upcoming/complex?all=${all}&email=${email}`
        );
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/upcoming/complex`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return null;
    }
  }

  // for the upcoming sessions section when event is ongoing or live
  public async getUpcomingSessions(): Promise<IComplexEvent[] | any> {
    try {
      const res = await axios.get(`${this.apiUrl}/custom/sessions/upcoming/complex`);
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  // get ongoing events
  public async getOngoingEvents(all?: boolean, email?: string): Promise<IComplexEvent[] | any> {
    try {
      let res = [];
      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(
          `${this.apiUrl}/custom/events/ongoing/complex?all=${all}&email=${email}`
        );
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/ongoing/complex`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  // get live events
  async getLiveEvents(all?: boolean, email?: string): Promise<IComplexEvent[] | any> {
    try {
      let res = [];
      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(
          `${this.apiUrl}/custom/events/live/complex?all=${all}&email=${email}`
        );
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/live/complex`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  async getSessionsForEvent(eventId: number): Promise<IComplexEventSession[] | MyError> {
    try {
      const response = await axios.get(
        `${this.apiUrl}/items/complex_event_sessions?fields=*.*.*.*.*&filter[complex_event_talk.event.id][eq]=${eventId}`,
        this.config
      );
      const data = response.data.data;

      if (data.length === 0) {
        return null;
      }

      const sessions = data.map((session) => this.preprocessSession(session));
      const sortedSessions = this.sortSessionsByOrderOrDate(sessions);

      return sortedSessions;
    } catch (error) {
      return {
        error,
      };
    }
  }

  async getLiveSessionsForEvent(eventId: number): Promise<IComplexEventSession[] | MyError> {
    try {
      const response = await axios.get(
        `${this.apiUrl}/custom/events/live/complex/sessions?eventId=${eventId}`
      );
      const data = response.data.live_sessions;

      if (data.length === 0) {
        return null;
      }

      const liveSessions = data
        .map((session) => this.preprocessSession(session))
        .sort((a, b) => (a.starts_at as Moment).valueOf() - (b.starts_at as Moment).valueOf());

      return liveSessions;
    } catch (error) {
      return {
        error,
      };
    }
  }

  // get past events
  public async getPastEvents(all?: boolean, email?: string): Promise<IComplexEvent[] | any> {
    try {
      let res = [];
      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(
          `${this.apiUrl}/custom/events/past/complex?all=${all}&email=${email}`
        );
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/past/complex`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (b.start_date as Moment).valueOf() - (a.end_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  isLive(session: IComplexEventSession): boolean {}

  public setEventStatus(start: Moment, end: Moment): EventStatus {
    // Clone moment object without reference to substract 10 mins
    const _start = start.clone();
    const now = TimeService.nowTz();

    // Set event status
    if (now.isBetween(start, end)) {
      return EventStatus.LIVE;
    } else if (now.isBetween(_start.subtract(2, 'weeks'), start)) {
      return EventStatus.ONGOING;
    } else if (now.isBefore(start)) {
      return EventStatus.UPCOMING;
    } else {
      return EventStatus.PAST;
    }
  }

  public setSessionStatus(start: Moment, end: Moment): SessionStatus {
    if (!start || !end) {
      return null;
    }

    // Clone moment object without reference to substract 10 mins
    const _start = start.clone();
    const now = TimeService.nowTz();

    // Set session status
    if (now.isBetween(start, end)) {
      return SessionStatus.LIVE;
    } else if (now.isBetween(_start.subtract(15, 'minutes'), start)) {
      return SessionStatus.SOON_LIVE;
    } else if (now.isBefore(start)) {
      return SessionStatus.BEFORE;
    } else {
      return SessionStatus.AFTER;
    }
  }

  private preprocessEvent(event: IComplexEvent): IComplexEvent {
    const startDate = TimeService.createTz(event.start_date);
    const endDate = TimeService.createTz(event.end_date);

    return {
      ...event,
      start_date: startDate,
      end_date: endDate,
      label: event.label
        ? {
            ...event.label,
            textColor: labelColor(event.label.color),
          }
        : {},
      status: this.setEventStatus(startDate, endDate),
      type: SessionType.COMPLEX,
    };
  }

  private preprocessTalk(talk: IComplexEventTalk): IComplexEventTalk {
    return {
      ...talk,
      event: this.preprocessEvent(talk.event as IComplexEvent),
      group: talk.group
        ? {
            ...talk.group,
            textColor: labelColor(talk.group.color),
          }
        : {},
      downloads: talk.downloads ? objectToArray(talk.downloads) : [],
      recordings: talk.recordings ? objectToArray(talk.recordings) : [],
      speakers: talk.speakers ? talk.speakers.map((speaker) => speaker.speaker_id) : [],
    };
  }

  private preprocessSession(session: IComplexEventSession): IComplexEventSession {
    const startAt = TimeService.createTz(`${session.date} ${session.starts_at}`);
    const endsAt = TimeService.createTz(`${session.date} ${session.ends_at}`);

    const processedSession = {
      ...session,
      modified_on: TimeService.createTz(session.modified_on),
      starts_at: startAt,
      ends_at: endsAt,
      status: this.setSessionStatus(startAt, endsAt),
      type: SessionType.COMPLEX,
      talk: this.preprocessTalk(session.complex_event_talk),
      event: this.preprocessEvent(session.complex_event_talk.event),
      order: session.complex_event_talk.sort,
    };

    delete processedSession.complex_event_talk;

    return processedSession;
  }

  private sortSessionsByOrderOrDate(sessions: IComplexEventSession[]): IComplexEventSession[] {
    const sortedComplexSessions = sessions.sort((a, b) => {
      if (!a.order && !b.order) return (a.starts_at as Moment).valueOf() - (b.starts_at as Moment).valueOf();
      if (!a.order) return 1;
      if (!b.order) return -1;
      return (
        a.order - b.order || (a.starts_at as Moment).valueOf() - (b.starts_at as Moment).valueOf()
      );
    });
    return sortedComplexSessions;
  }
}

export default ComplexEventsService.getInstance();
