import React, { useState, useEffect } from 'react';
import FooterService from '../../services/footer.service';
import IFooter from '../../interfaces/footer.interface';

import footer1 from '../../assets/logos/footer1.png';
import footer2 from '../../assets/logos/footer2.png';
import footer3 from '../../assets/logos/footer3.png';

const Footer: React.FC = () => {
  const [content, setContent] = useState({} as IFooter);

  useEffect(() => {
    if (Object.keys(content).length === 0 && content.constructor === Object) {
      getContent();
    }
  }, []);

  const getContent = async () => {
    const response = await FooterService.getContent();

    if (response) {
      setContent(response);
    }
  };

  return (
    <React.Fragment>
      <section id="kontakt" className="bg-blue color-white">
        <div className="container padding-top-2 padding-bottom-0">
          <div className="row padding-left-1">
            <div className="col col-auto margin-right-2">
              <div className="row margin-top-0 margin-bottom-0_25 font-weight-bold">
                Der Verbund
              </div>
              <div className="row margin-bottom-2">
                <a className="partnerLogo margin-bottom-1">
                  <img src={footer1} alt="Bildungswerk"></img>
                </a>
              </div>
            </div>
            <div className="col col-auto margin-right-2">
              <div className="row margin-top-0 margin-bottom-0_25 font-weight-bold">&nbsp;</div>
              <div className="row margin-bottom-2">
                <a className="partnerLogo margin-bottom-1">
                  <img src={footer2} alt="Udw"></img>
                </a>
              </div>
            </div>
            <div className="col col-auto margin-right-2">
              <div className="row margin-top-0 margin-bottom-0_25 font-weight-bold">Partner</div>
              <div className="row margin-bottom-2">
                <a className="partnerLogo margin-bottom-1">
                  <img src={footer3} alt="VU"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-darkblue color-white">
        <div className="container padding-top-2 padding-bottom-2">
          <div className="row">
            <div className="col-12 col-sm-10 margin-top-0">
              <a
                className="text-gray d-inline-block margin-right-2"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bildungswerk-wirtschaft.de/de/ueber_uns/impressum"
              >
                Impressum
              </a>
              <a
                className="text-gray d-inline-block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bildungswerk-wirtschaft.de/de/ueber_uns/datenschutzerklaerung"
              >
                Datenschutz
              </a>
            </div>
          </div>
        </div>
      </footer>

      <footer className="footer margin-top-0 bg-color-gray-shade-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="sitemap">
                <div className="row">
                  {content && content.first_column_links && content.first_column_links.length > 0 && (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                      <h5 className="sitemap__title">{content.first_column_title}</h5>
                      <ul className="bare-list sitemap__list">
                        {content.first_column_links.map((link, i) => (
                          <li className="sitemap__item" key={`first_column_${i}`}>
                            {/* eslint-disable-next-line */}
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="sitemap__link"
                            >
                              {link.description}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {content &&
                    content.second_column_links &&
                    content.second_column_links.length > 0 && (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <h5 className="sitemap__title">{content.second_column_title}</h5>
                        <ul className="bare-list sitemap__list">
                          {content.second_column_links.map((link, i) => (
                            <li className="sitemap__item" key={`first_column_${i}`}>
                              {/* eslint-disable-next-line */}
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="sitemap__link"
                              >
                                {link.description}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  {content && content.third_column_text && content.third_column_text !== '' && (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                      <h5 className="sitemap__title">{content.third_column_title}</h5>
                      <p
                        className="sitemap__free-text"
                        dangerouslySetInnerHTML={{ __html: content.third_column_text }}
                      ></p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
