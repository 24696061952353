import React from 'react';
import { Moment } from 'moment';
import Label from '../components/Label';
import { IComplexEventTalk, IComplexEventSession } from '../interfaces/complexEvent.interface';
import defaultImage from '../assets/img/defaultImage.png';

interface Props {
  live?: boolean;
  talk?: IComplexEventTalk;
  session?: IComplexEventSession;
  openDetails?: (talk: IComplexEventTalk) => void;
}

const defaultProps = {
  live: false,
};

const SessionCard: React.FC<Props> = (props) => {
  const data: IComplexEventTalk = props.talk
    ? (props.talk as IComplexEventTalk)
    : (props.session.talk as IComplexEventTalk);

  const imgSrc = data.image ? data.image?.data?.full_url : defaultImage;

  return (
    /* eslint-disable */
    <a
      className="card card--rimless"
      onClick={() => (props.openDetails ? props.openDetails(data) : null)}
    >
      <div className="card__image position-relative">
        {props.live && <span className="label bg-color-red-shade-1 live__label">LIVE</span>}
        <div className="aspect-ratio-4x3 bg-color-black aspect-ratio--cover">
          <img alt={data.description_short} src={imgSrc} />
          <button className="button button--control sc-link icon-external-link">
            Open Session
          </button>
        </div>
      </div>
      <div className="card__body">
        <h5 className="card__title">{data.title}</h5>
        {props.session && (
          <p className="font-size-xsmall margin-bottom-0_25">
            {(props.session.starts_at as Moment).format('DD.MM.YYYY, k:mm')} –{' '}
            {(props.session.ends_at as Moment).format('k:mm')} Uhr
          </p>
        )}
        <div>
          {data.group && data.group.label && (
            <Label
              title={data.group.label}
              titleColor={data.group.textColor}
              labelColor={data.group.color}
            />
          )}
          <br />
        </div>

        <p>{data.description_short}</p>
      </div>
    </a>
  );
};

SessionCard.defaultProps = defaultProps;

export default SessionCard;
