import React, { Component, ReactElement } from 'react';
import Loader from '../../Loader';
import DefaultHeader from '../../DefaultHeader';
import LiveHeader from '../../LiveHeader';
import News from '../../News';
import UpcomingEvents from './components/UpcomingEvents';
import SimpleEventsService from '../../../services/simpleEvents.service';
import StartPageService from '../../../services/startPage.service';
import { ISimpleEvent, ISimpleEventSession } from '../../../interfaces/simpleEvent.interface';
import { UserContext } from '../../UserProvider';
import ComplexEventsService from '../../../services/complexEvents.service';
import { IComplexEvent, IComplexEventSession } from '../../../interfaces/complexEvent.interface';

interface Props {
  openDetails: () => void;
}
interface State {
  loading: boolean;
  content?: any;
  isEditorMode: boolean;
  liveComplexEvents: IComplexEvent[];
  complexEventSessions: IComplexEventSession[];
  liveSimpleEvents?: ISimpleEvent[];
  liveSimpleEventSessions?: ISimpleEventSession[];
  timers?: {
    initialTimeout?: any;
    liveInterval?: any;
  };
}

class Home extends Component<Props, State> {
  static contextType = UserContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      isEditorMode: false,
      liveComplexEvents: [],
      complexEventSessions: [],
      liveSimpleEvents: [],
      liveSimpleEventSessions: [],
    };
  }

  componentDidMount(): void {
    this.getContent();
    this.getAllEvents();

    const initialTimeout = setTimeout(() => {
      const liveInterval = setInterval(() => {
        this.getLiveSimpleEvents();
        this.setState((prevState: State) => {
          return {
            ...prevState,
            complexEventSessions: prevState.complexEventSessions.map((session) =>
              this.updateComplexSessionStatus(session)
            ),
          };
        });
      }, 10000);
      this.setState({ timers: { liveInterval } });
    }, 10000);

    this.setState({ timers: { initialTimeout } });
  }

  componentDidUpdate(): void {
    if (this.context.editorMode !== this.state.isEditorMode) {
      this.setState({ isEditorMode: this.context.editorMode }, () => this.getAllEvents());
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.state.timers.initialTimeout);
    clearInterval(this.state.timers.liveInterval);
  }

  async getContent(): Promise<void> {
    const content = await StartPageService.getContent();

    if (!content) {
      return;
    }

    this.setState({ content });
  }

  async getLiveComplexEvents(): Promise<void> {
    const events = (await ComplexEventsService.getLiveEvents(
      this.state.isEditorMode,
      this.context.user.email
    )) as IComplexEvent[];

    if (!events) {
      this.setState({ liveComplexEvents: [], loading: false });
      return;
    }

    this.setState({ liveComplexEvents: events }, () => {
      if (events.length > 0) {
        this.getLiveComplexSessions();
      }
    });
  }

  async getLiveComplexSessions(): Promise<void> {
    let complexEventSessions = [];

    for (const event of this.state.liveComplexEvents) {
      const sessions = (await ComplexEventsService.getSessionsForEvent(
        event.id
      )) as IComplexEventSession[];

      if (sessions) {
        complexEventSessions = [...complexEventSessions, ...sessions];
      }
    }

    this.setState({ complexEventSessions: complexEventSessions, loading: false });
  }

  async getLiveSimpleEvents(): Promise<void> {
    const events = (await SimpleEventsService.getLiveEvents(
      this.state.isEditorMode,
      this.context.user.email
    )) as ISimpleEvent[];

    if (!events) {
      this.setState({ liveSimpleEvents: [], liveSimpleEventSessions: [], loading: false });
      return;
    }

    this.setState({ liveSimpleEvents: events }, () => this.getLiveSimpleSessions());
  }

  async getLiveSimpleSessions(): Promise<void> {
    let liveEventsSessions = [];

    for (const event of this.state.liveSimpleEvents) {
      const sessions = (await SimpleEventsService.getSessionsForEvent(
        event.id
      )) as ISimpleEventSession[];

      if (sessions) {
        liveEventsSessions = [...liveEventsSessions, ...sessions];
      }
    }

    this.setState({ liveSimpleEventSessions: liveEventsSessions });
  }

  updateComplexSessionStatus(session: IComplexEventSession): IComplexEventSession {
    return {
      ...session,
      status: ComplexEventsService.setSessionStatus(session.starts_at, session.ends_at),
    };
  }

  getAllEvents(): void {
    this.getLiveSimpleEvents();
    this.getLiveComplexEvents();
  }

  refreshSessions(): void {
    this.getLiveSimpleEvents();
    this.setState((prevState: State) => {
      return {
        ...prevState,
        complexEventSessions: prevState.complexEventSessions.map((session) =>
          this.updateComplexSessionStatus(session)
        ),
      };
    });
  }

  private isLive(): boolean {
    return this.state.liveSimpleEvents.length > 0 || this.state.liveComplexEvents.length > 0;
  }

  private renderHeader(): ReactElement {
    if (this.state.content) {
      if (this.isLive()) {
        return (
          <>
            <DefaultHeader
              title={this.state.content.header_title}
              subtitle={this.state.content.header_subtitle}
              text={this.state.content.header_description}
            />
            <LiveHeader
              title={this.state.content.header_title}
              sessions={[...this.state.liveSimpleEventSessions, ...this.state.complexEventSessions]}
              refreshSessions={this.refreshSessions.bind(this)}
              openDetails={this.props.openDetails}
            />
          </>
        );
      } else {
        return (
          <DefaultHeader
            title={this.state.content.header_title}
            subtitle={this.state.content.header_subtitle}
            text={this.state.content.header_description}
          />
        );
      }
    }
  }

  render(): ReactElement {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <>
            {this.renderHeader()}

            <div className="margin-top-2">
              <News showOnHome={true} />
            </div>

            <UpcomingEvents noEventsDescription={this.state.content?.no_events_text} />
          </>
        )}
      </>
    );
  }
}

export default Home;
