import axios from 'axios';
import { Moment } from 'moment-timezone';
import TimeService from './time.service';
import { ISimpleEvent, ISimpleEventSession } from '../interfaces/simpleEvent.interface';
import { EventStatus, SessionStatus } from '../enums/status.enum';
import { SessionType } from '../enums/sessionType.enum';
import labelColor from '../utils/labelColor';
import objectToArray from '../utils/objectToArray';

class SimpleEventsService {
  private static instance: SimpleEventsService;
  private apiUrl: string = process.env.REACT_APP_API_URL;

  private config: any = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  async getEvents(): Promise<ISimpleEvent | any> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/simple_events?fields=*.*,speakers.speaker_id.*.*&filter[active][eq]=1`,
        this.config
      );
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data.map((event) => this.preprocessEvent(event));

      return events;
    } catch (error) {
      return null;
    }
  }

  async getEvent(id: number): Promise<ISimpleEvent | any> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/simple_events?fields=*.*,label.*,speakers.speaker_id.*.*&filter[id][eq]=${id}&filter[active][eq]=1`,
        this.config
      );
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const event = data.map((event) => this.preprocessEvent(event));

      return event[0];
    } catch (error) {
      return null;
    }
  }

  async getEventBySlug(slug: string): Promise<ISimpleEvent | any> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/simple_events?fields=*.*,label.*,speakers.speaker_id.*.*&filter[slug][eq]=${slug}`,
        this.config
      );
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const event = data.map((event) => this.preprocessEvent(event));

      return event[0];
    } catch (error) {
      return null;
    }
  }

  getSessions(): ISimpleEventSession[] { }

  async getSessionsForEvent(id: number): Promise<ISimpleEventSession[] | any> {
    try {
      const res = await axios.get(
        `${this.apiUrl}/items/simple_event_sessions?fields=*.*.*&filter[simple_event.id][eq]=${id}`,
        this.config
      );
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const sessions = data
        .map((session) => this.preprocessSession(session))
        .sort((a, b) => (a.starts_at as Moment).valueOf() - (b.starts_at as Moment).valueOf());

      return sessions;
    } catch (error) {
      return null;
    }
  }

  // for the upcoming page
  public async getUpcomingEvents(all?: boolean, email?: string): Promise<ISimpleEvent[] | any> {
    try {
      let res = [];

      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(
          `${this.apiUrl}/custom/events/upcoming/simple?all=${all}&email=${email}`
        );
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/upcoming/simple`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return null;
    }
  }

  // for the upcoming sessions section when event is ongoing or live
  getUpcomingSessions(event: ISimpleEvent): ISimpleEvent[] { }

  // get ongoing events
  public async getOngoingEvents(all?: boolean, email?: string): Promise<ISimpleEvent[] | any> {
    try {
      let res = [];

      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(
          `${this.apiUrl}/custom/events/ongoing/simple?all=${all}&email=${email}`
        );
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/ongoing/simple`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  // get live events
  async getLiveEvents(all?: boolean, email?: string): Promise<ISimpleEvent[] | any> {
    try {
      let res = [];

      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(`${this.apiUrl}/custom/events/live/simple?all=${all}&email=${email}`);
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/live/simple`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (a.start_date as Moment).valueOf() - (b.start_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  // get live sessions (e.g. for header)
  getLiveSessions(event: ISimpleEvent): ISimpleEventSession[] { }

  public async getPastEvents(all?: boolean, email?: string): Promise<ISimpleEvent[] | any> {
    try {
      let res = [];

      if (all && all !== undefined && email && email !== undefined) {
        res = await axios.get(`${this.apiUrl}/custom/events/past/simple?all=${all}&email=${email}`);
      } else {
        res = await axios.get(`${this.apiUrl}/custom/events/past/simple`);
      }
      const data = res.data.data;

      if (data.length === 0) {
        return null;
      }

      const events = data
        .map((event) => this.preprocessEvent(event))
        .sort((a, b) => (b.start_date as Moment).valueOf() - (a.end_date as Moment).valueOf());

      return events;
    } catch (error) {
      return {
        error,
      };
    }
  }

  isLive(session: ISimpleEventSession): boolean { }

  static getInstance(): SimpleEventsService {
    if (!SimpleEventsService.instance) {
      SimpleEventsService.instance = new SimpleEventsService();
    }

    return SimpleEventsService.instance;
  }

  /**
   * Preprocess the event for the frontend
   * @param event
   */
  private preprocessEvent(event: ISimpleEvent): ISimpleEvent {
    const startDate = event.start_date ? TimeService.createTz(event.start_date) : null;
    const endDate = event.end_date ? TimeService.createTz(event.end_date) : null;

    return {
      ...event,
      start_date: startDate,
      end_date: endDate,
      status: this.setEventStatus(startDate, endDate),
      label: event.label
        ? {
          ...event.label,
          textColor: labelColor(event.label.color),
        }
        : {},
      downloads: event.downloads ? objectToArray(event.downloads) : [],
      recordings: event.recordings ? objectToArray(event.recordings) : [],
      type: SessionType.SINGLE,
    };
  }

  /**
   * Preprocess the session for the frontend
   * @param session
   */
  private preprocessSession(session: ISimpleEventSession): ISimpleEventSession {
    const startsAt = TimeService.createTz(`${session.date} ${session.starts_at}`);
    const endsAt = TimeService.createTz(`${session.date} ${session.ends_at}`);

    const preprocessedSession = {
      ...session,
      type: SessionType.SINGLE,
      // rename simple_event to event
      event: this.preprocessEvent((session as any).simple_event),
      status: this.setSessionStatus(startsAt, endsAt),
      starts_at: startsAt.local(),
      ends_at: endsAt.local(),
    };

    // Remove simple_event after it has been renamed to event
    delete (preprocessedSession as any).simple_event;

    return preprocessedSession;
  }

  public setEventStatus(start: Moment, end: Moment): EventStatus {
    // Clone moment object without reference to substract 10 mins
    const _start = start.clone();
    const now = TimeService.nowTz();

    // Set event status
    if (now.isBetween(start, end)) {
      return EventStatus.LIVE;
    } else if (now.isBetween(_start.subtract(2, 'weeks'), start)) {
      return EventStatus.ONGOING;
    } else if (now.isBefore(start)) {
      return EventStatus.UPCOMING;
    } else {
      return EventStatus.PAST;
    }
  }

  public setSessionStatus(start: Moment, end: Moment): SessionStatus {
    if (!start || !end) {
      return null;
    }

    // Clone moment object without reference to substract 10 mins
    const _start = start.clone();
    const now = TimeService.nowTz();

    // Set session status
    if (now.isBetween(start, end)) {
      return SessionStatus.LIVE;
    } else if (now.isBetween(_start.subtract(15, 'minutes'), start)) {
      return SessionStatus.SOON_LIVE;
    } else if (now.isBefore(start)) {
      return SessionStatus.BEFORE;
    } else {
      return SessionStatus.AFTER;
    }
  }
}

export default SimpleEventsService.getInstance();
