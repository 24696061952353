import React, { ReactElement, useReducer, useEffect } from 'react';
import moment from 'moment-timezone';
import { SessionStatus } from '../enums/status.enum';
import { defaultTimezone } from '../const/defaultTimezone';
import { ISimpleEventSession } from '../interfaces/simpleEvent.interface';
import { IComplexEventSession } from '../interfaces/complexEvent.interface';

interface Props {
  session: ISimpleEventSession | IComplexEventSession;
  didFinishProgress: () => boolean;
}

interface State {
  isBefore: boolean;
  progress: number;
  playTime: number | null;
  interval: number | null;
  hasNewSession: boolean;
}

const progressBarReducer = (state, action) => {
  switch (action.type) {
    case 'setIsBefore':
      return { ...state, isBefore: action.payload };
    case 'setHasNewSession':
      return { ...state, hasNewSession: action.payload };
    case 'setProgress':
      return { ...state, progress: action.payload };
    case 'setPlayTime':
      return { ...state, playTime: action.payload };
    case 'setInterval':
      return { ...state, interval: action.payload };
    default:
      return state;
  }
};

const ProgressBar: React.FC<Props> = ({ session, didFinishProgress }): ReactElement => {
  const initialState: State = {
    isBefore: true,
    progress: 0,
    playTime: null,
    interval: null,
    hasNewSession: true,
  };
  const [state, dispatch] = useReducer(progressBarReducer, initialState);

  useEffect(() => {
    getProgressUpdate();

    return () => {
      clearInterval(state.interval);
    };
  }, [session]);

  const getProgressUpdate = () => {
    const interval = setInterval(() => {
      getProgress();
    }, 1000);

    dispatch({ type: 'setInterval', payload: interval });
  };

  const getProgress = () => {
    if (session) {
      const now = moment().tz(defaultTimezone);
      const isBefore = session.status === SessionStatus.SOON_LIVE;

      if (isBefore) {
        dispatch({ type: 'setIsBefore', payload: isBefore });
        dispatch({ type: 'setProgress', payload: 0 });
        dispatch({ type: 'setPlayTime', payload: null });
        return;
      }

      const duration = session.ends_at.valueOf() - session.starts_at.valueOf();
      const playTime = now.valueOf() - session.starts_at.valueOf();
      const progress = ((100 / duration) * playTime).toFixed(1);

      if (now.valueOf() >= session.ends_at.valueOf()) {
        didFinishProgress();
        clearInterval(state.interval);
        dispatch({ type: 'setHasNewSession', payload: true });
        return;
      }

      dispatch({ type: 'setIsBefore', payload: false });
      dispatch({ type: 'setProgress', payload: progress });
      dispatch({ type: 'setPlayTime', payload: moment(playTime).utc().format('HH:mm:ss') });
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <h4 className="color-white">
          {state.isBefore ? (
            <span>Beginnt in Kürze</span>
          ) : (
            <span>Jetzt live – {state.playTime}</span>
          )}
        </h4>
        {!state.isBefore && (
          <div className="progressbar__container bg-color-white margin-bottom-1">
            <div
              className="progressbar__indicator bg-color-font"
              style={{ width: `${state.progress}%` }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
