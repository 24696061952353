import React from 'react';
import CalendarService from '../services/calendar.service';
import { ISimpleEventSession } from '../interfaces/simpleEvent.interface';
import { IComplexEventSession } from '../interfaces/complexEvent.interface';
import { Moment } from 'moment';
// import momentTZ from 'moment-timezone';

interface Props {
  session?: ISimpleEventSession | IComplexEventSession;
}

const importToCalendar = async (session) => {
  const response = await CalendarService.getCalendarEvent(session);
  download(session, response.data);
};

const download = (session: ISimpleEventSession | IComplexEventSession, data: any) => {
  if (data) {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE11 Hack
      const viewLink = document.createElement('a');
      viewLink.setAttribute('style', 'display: none; visibility: hidden');
      document.body.appendChild(viewLink);
      viewLink.onclick = () => {
        window.navigator.msSaveBlob(
          new Blob([data], { type: 'text/calendar' }),
          `session${session.id}.ics`
        );
      };
      viewLink.click();
    } else {
      const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'text/calendar' }));
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = `session${session.id}.ics`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // window.open(fileURL, '_blank');
    }
  }
};

const CalendarDownload: React.FC<Props> = (props) => {
  return (
    /* eslint-disable */
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();

        importToCalendar(props.session);
      }}
    >
      <i className="icon-calendar"></i>&nbsp;
      {(props.session.starts_at as Moment).format('DD.MM.YYYY')},&nbsp;
      {(props.session.starts_at as Moment).format('k:mm')} -{' '}
      {(props.session.ends_at as Moment).format('k:mm')} Uhr
      {/* ({momentTZ.tz.guess(true)}) */}
    </a>
  );
};

export default CalendarDownload;
