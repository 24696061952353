import React from 'react';

interface Props {
  title: string;
  icon?: string;
  data: any[];
}

const defaultProps = {
  icon: 'icon-extern',
};

const ViewFiles: React.FC<Props> = (props) => {
  if (props.data && props.data.length > 0) {
    return (
      <>
        <h6 className="margin-bottom-1">{props.title}</h6>
        {props.data.map((file, i) => (
          <div
            key={`file-downloads-${file.url}-${i}`}
            className="col-12 margin-bottom-0_5 padding-0"
          >
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <i className={props.icon}></i> {file.description}
            </a>
          </div>
        ))}
      </>
    );
  }
  return null;
};

ViewFiles.defaultProps = defaultProps;

export default ViewFiles;
