import React, { Component, ReactElement } from 'react';
import { Moment } from 'moment';
import Label from '../../Label';
import DefaultHeader from '../../DefaultHeader';
import LiveHeader from '../../LiveHeader';
import CustomFooter from '../../CustomFooter';
import Loader from '../../Loader';
import ViewFiles from '../../ViewFiles';
import { ISimpleEvent, ISimpleEventSession } from '../../../interfaces/simpleEvent.interface';
import SimpleEventsService from '../../../services/simpleEvents.service';
import CalendarDownload from '../../CalendarDownload';
import { SessionStatus, EventStatus } from '../../../enums/status.enum';
import timeService from '../../../services/time.service';
import { now } from 'moment';

interface Props {
  match?: any;
}
interface State {
  isLoading: boolean;
  event?: ISimpleEvent;
  sessions?: ISimpleEventSession[];
  timers?: {
    updateInterval?: any;
  };
}

class SimpleEvent extends Component<Props, State> {
  private now: Moment = timeService.nowTz();

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      event: null,
    };
  }

  componentWillMount(): void {
    const params = this.props.match.params;

    if (params && params.slug) {
      this.getEventBySlug(params.slug);
    }
  }

  componentDidMount(): void {
    this.setUpdateInterval();
  }

  componentWillUnmount(): void {
    clearInterval(this.state.timers.updateInterval);
  }

  componentDidUpdate(prevProps, prevState): void {
    if (
      prevProps.match.params.slug &&
      prevProps.match.params.slug !== this.props.match.params.slug
    ) {
      clearInterval(this.state.timers.updateInterval);

      this.setState({ isLoading: true });

      this.getEventBySlug(this.props.match.params.slug);
      this.setUpdateInterval();
    }
  }

  getCurrentLiveSession(): ISimpleEventSession {
    if (
      this.state.event &&
      this.state.event.status === EventStatus.LIVE &&
      this.state.sessions &&
      this.state.sessions.length > 0
    ) {
      return this.state.sessions.filter(
        (session) =>
          session.status === SessionStatus.LIVE || session.status === SessionStatus.SOON_LIVE
      )[0];
    }
    if (this.state.sessions && this.state.sessions.length > 0) {
      return this.state.sessions[0];
    }

    return null;
  }

  refreshSessions(): void {
    if (!this.state.event) {
      return;
    }

    this.getSessionsForEvent(this.state.event.id);
  }

  private setUpdateInterval(): void {
    const params = this.props.match.params;

    const interval = setInterval(() => this.getEventBySlug(params.slug), 10000);

    this.setState({ timers: { updateInterval: interval } });
  }

  private async getEventBySlug(slug: string): Promise<any> {
    if (slug) {
      const event = (await SimpleEventsService.getEventBySlug(slug)) as ISimpleEvent;

      if (!event) {
        console.error('No event found for the given slug');
        return;
      }

      this.setState({ event, isLoading: false }, () => this.getSessionsForEvent(event.id));
    }
  }

  private async getSessionsForEvent(id: number): Promise<any> {
    if (id) {
      const sessions = (await SimpleEventsService.getSessionsForEvent(id)) as ISimpleEventSession[];

      if (!sessions) {
        return;
      }

      this.setState({ sessions });
    }
  }

  private isLive(): boolean {
    if (this.state.event) {
      return this.state.event.status === EventStatus.LIVE;
    }

    return false;
  }

  private hasLinks(): boolean {
    return (
      (this.state.sessions && this.state.sessions.length > 0) ||
      (this.state.event.downloads && this.state.event.downloads.length > 0) ||
      (this.state.event.recordings && this.state.event.recordings.length > 0)
    );
  }

  private isRegistrationAvailable(): boolean {
    return (
      !this.state.event.disable_registration_on ||
      this.now.isBefore(this.state.event.disable_registration_on)
    );
  }

  private showParticipateBtn(): boolean {
    return (
      this.getCurrentLiveSession() &&
      this.getCurrentLiveSession().live_url &&
      this.isRegistrationAvailable()
    );
  }

  private renderDate(): ReactElement {
    let date = null;

    if (this.state.event) {
      if (
        this.state.event.start_date &&
        (!this.state.event.estimated_date || this.state.event.estimated_date === '')
      ) {
        date = (this.state.event.start_date as Moment).format('DD.MM.YYYY');
      } else if (this.state.event.estimated_date !== '') {
        date = this.state.event.estimated_date;
      } else {
        return <></>;
      }

      return <h3 className="margin-top-0">{date}</h3>;
    }
  }

  render(): ReactElement {
    return (
      <>
        {this.state.isLoading && <Loader />}
        {this.state.event && !this.state.isLoading && (
          <div>
            {this.isLive() ? (
              <>
                <DefaultHeader
                  title={this.state.event.header_title}
                  subtitle={this.state.event.header_subtitle}
                  headimg={this.state.event.headimg?.data.full_url}
                />
                <LiveHeader
                  title={this.state.event.header_title}
                  subtitle={this.state.event.header_subtitle}
                  sessions={this.state.sessions}
                  refreshSessions={this.refreshSessions.bind(this)}
                />
              </>
            ) : (
              <DefaultHeader
                title={this.state.event.header_title}
                subtitle={this.state.event.header_subtitle}
                headimg={this.state.event.headimg?.data.full_url}
              />
            )}

            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12">{this.renderDate()}</div>
                </div>
                <div className="row margin-bottom-1">
                  <div className="col-12 col-md-12 col-lg-5 col-xl-5">
                    <div className="aspect-ratio-4x3 bg-color-black margin-bottom-1 aspect-ratio--cover">
                      {this.state.event.image ? (
                        <img
                          alt={this.state.event.header_title}
                          src={this.state.event.image?.data.full_url}
                        />
                      ) : (
                        <p className="text-align-center margin-1">
                          {this.state.event.header_title}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-7 col-xl-7">
                    <h4 className="margin-0">{this.state.event.header_title}</h4>
                    {/* Event label */}
                    {this.state.event.label && (
                      <Label
                        title={this.state.event.label.title}
                        titleColor={this.state.event.label.textColor}
                        labelColor={this.state.event.label.color}
                      />
                    )}
                    {/* Duration label */}
                    <Label
                      title={this.state.event.duration_label_text}
                      extraClasses={'label--has-outline'}
                    />

                    <p className="margin-top-1">
                      {this.showParticipateBtn() && (
                        <button
                          className="button button--primary icon-extern attendBtn margin-right-1"
                          onClick={() => window.open(this.getCurrentLiveSession().live_url)}
                        >
                          Jetzt teilnehmen
                        </button>
                      )}
                      {this.state.event.registration_url && this.isRegistrationAvailable && (
                        <button
                          className="button button--secondary attendBtn margin-top-1"
                          onClick={() => window.open(this.state.event.registration_url)}
                        >
                          {this.state.event.registration_button_label || 'Jetzt anmelden'}
                        </button>
                      )}
                    </p>

                    <div className="margin-top-1">
                      {this.state.event.lead_text && <h5>{this.state.event.lead_text}</h5>}
                      <p>{this.state.event.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.hasLinks() && (
              <div className="section bg-color-gray-tint-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="col-12 margin-bottom-0_5 padding-0">
                        {this.state.sessions && (
                          <>
                            <h3 className="margin-bottom-1">Termine</h3>
                            {this.state.sessions.map((session, i) => (
                              <div key={`simple_session_${i}`}>
                                <CalendarDownload key={`simple_session_${i}`} session={session} />
                              </div>
                            ))}
                          </>
                        )}
                      </div>

                      {this.state.event.downloads && (
                        <ViewFiles title={'View documents'} data={this.state.event.downloads} />
                      )}
                      {this.state.event.recordings && (
                        <ViewFiles title={'View recordings'} data={this.state.event.recordings} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* SPEAKERS */}
            <div className="section">
              <div className="container">
                <h3>{this.state.event.speaker_label || 'Sprecher'}</h3>
                {this.state.event.speakers
                  .sort((a, b) => a.sort - b.sort)
                  .map(({ speaker_id: speaker }: any, i) => (
                    <div key={`${speaker.id}-${i}`} className="row">
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2">
                        <div className="aspect-ratio-1x1 aspect-ratio--cover margin-bottom-1">
                          <img alt={speaker.last_name} src={speaker.image?.data.full_url} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-5">
                        <h5 className="margin-0">
                          {speaker.title} {speaker.first_name} {speaker.last_name}
                        </h5>
                        {speaker.job_title && (
                          <small className="font-size-xsmall">{speaker.job_title}</small>
                        )}
                        {/* <p className="margin-top-0_5">{speaker.speaker_id.description}</p> */}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* CUSTOM FOOTER */}
            {this.state.event.custom_footer && (
              <CustomFooter customFooter={this.state.event.custom_footer} />
            )}
          </div>
        )}
      </>
    );
  }
}

export default SimpleEvent;
