import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';
import Label from '../components/Label';
import { IEventCard } from '../interfaces/eventCard.interface';
import defaultImage from '../assets/img/defaultImage.png';

interface Props {
  event?: IEventCard;
}

const EventCard: React.FC<Props> = (props) => {
  const data: IEventCard = props.event;

  const imgSrc = data.image ? data.image?.data?.full_url : defaultImage;

  const renderDate = () => {
    let date = null;

    if (data.start_date && (!data.estimated_date || data.estimated_date === '')) {
      date = (data.start_date as Moment).format('DD.MM.YYYY');
      if (data.complex && (data.start_date as Moment).isSame(data.end_date as Moment, 'day')) {
        date = `${(data.start_date as Moment).format(
          'DD.MM.YYYY, k:mm'
        )} Uhr - ${(data.end_date as Moment).format('k:mm')} Uhr`;
      } else if (data.complex) {
        date = `${(data.start_date as Moment).format(
          'DD.MM.YYYY'
        )} - ${(data.end_date as Moment).format('DD.MM.YYYY')}`;
      }
    } else if (data.estimated_date !== '') {
      date = data.estimated_date;
    } else {
      return <></>;
    }

    return <p className="font-size-xsmall margin-bottom-0_25">{date}</p>;
  };

  const content = () => (
    <>
      <div className="card__image position-relative">
        <div className="aspect-ratio-4x3 bg-color-black aspect-ratio--cover">
          <img alt={data.description_short} src={imgSrc} />
          <button className="button button--control sc-link icon-external-link">
            Veranstaltung öffnen
          </button>
        </div>
      </div>
      <div className="card__body">
        <h5 className="card__title">{data.header_title}</h5>
        {renderDate()}
        <div>
          {/* Event label */}
          {data.label && (
            <Label
              title={data.label.title}
              titleColor={data.label.textColor}
              labelColor={data.label.color}
            />
          )}

          {/* Duration label */}
          {data.duration_label_text && (
            <Label title={data.duration_label_text} extraClasses={'label--has-outline'} />
          )}
          <br />
        </div>
        <h6>{data.lead_text}</h6>
        <p>{data.description_short}</p>
        {data.complex && <div className="button">Veranstaltungsübersicht</div>}
      </div>
    </>
  );

  return (
    <Link
      to={data.complex ? `/conference/${data.slug}` : `/event/${data.slug}`}
      className="card card--rimless"
      target="_self"
      rel="noopener noreferrer"
    >
      {content()}
    </Link>
  );
};

export default EventCard;
