import React from 'react';
import { Moment } from 'moment';
import { Link } from 'react-router-dom';
import Label from '../components/Label';
import { ISimpleEventSession, ISimpleEvent } from '../interfaces/simpleEvent.interface';
import defaultImage from '../assets/img/defaultImage.png';
interface Props {
  live?: boolean;
  canNavigate?: boolean;
  canNavigateIntern?: boolean;
  event?: ISimpleEvent;
  session?: ISimpleEventSession;
}

const defaultProps = {
  live: false,
  canNavigate: false,
  canNavigateIntern: false,
};

const SimpleSessionCard: React.FC<Props> = (props) => {
  const data: ISimpleEvent = props.event ? props.event : props.session.event;

  const imgSrc = data.image ? data.image?.data?.full_url : defaultImage;

  const content = () => (
    <>
      <div className="card__image position-relative">
        {props.live && <span className="label bg-color-red-shade-1 live__label">LIVE</span>}
        <div className="bg-color-black">
          <img alt={data.description_short} src={imgSrc} />

          {props.canNavigate && (
            <button className="button button--control sc-link icon-external-link">
              Open Session
            </button>
          )}
        </div>
      </div>
      <div className="card__body">
        <h5 className="card__title">{data.header_title}</h5>
        <div>
          {data.label && (
            <Label
              title={data.label.title}
              titleColor={data.label.textColor}
              labelColor={data.label.color}
            />
          )}
          <br />
          {props.session && (
            <span>
              {(props.session.starts_at as Moment).format('k:mm')} –{' '}
              {(props.session.ends_at as Moment).format('k:mm')} Uhr
            </span>
          )}
        </div>

        <p>{data.description_short}</p>
      </div>
    </>
  );

  if (props.canNavigateIntern) {
    return (
      <Link to={`/event/${data.slug}`} className="card card--rimless">
        {content()}
      </Link>
    );
  }
  if (props.session && props.canNavigate) {
    return (
      <a
        href={props.session.live_url}
        className="card card--rimless"
        target="_blank"
        rel="noopener noreferrer"
      >
        {content()}
      </a>
    );
  }

  return <div className="card card--rimless">{content()}</div>;
};

SimpleSessionCard.defaultProps = defaultProps;

export default SimpleSessionCard;
