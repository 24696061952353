import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SimpleEventsService from '../../services/simpleEvents.service';
import ComplexEventsService from '../../services/complexEvents.service';
import { IEventCard } from '../../interfaces/eventCard.interface';
import { useUser } from '../UserProvider';
import { SessionType } from '../../enums/sessionType.enum';
// import logo from '../../assets/logos/logo-small.png';
import logo from '../../assets/logos/new-logo.jpg';

interface Props {
  events?: IEventCard[];
}

const Topbar: React.FC<Props> = (props: Props) => {
  const [isopen, setIsOpen] = useState(false);
  const [hasUpcoming, setHasUpcoming] = useState(false);
  const [events, setEvents] = useState(null);
  const { user, isEditor, editorMode, setEditorMode } = useUser();

  const toggleEditorMode = () => {
    setEditorMode(!editorMode);
  };

  useEffect(() => {
    setEvents(props.events?.filter((event) => event.active));
  }, [props]);

  useEffect(() => {
    async function getUpcomingEvents() {
      const simpleEvents = await SimpleEventsService.getUpcomingEvents(editorMode, user.email);
      const complexEvents = await ComplexEventsService.getUpcomingEvents(editorMode, user.email);

      let events = [];
      if (simpleEvents && complexEvents) {
        events = simpleEvents.concat(complexEvents);
      } else if (simpleEvents || complexEvents) {
        events = simpleEvents || complexEvents;
      }

      setHasUpcoming(events && events.length > 0);
    }
    getUpcomingEvents();

    if (editorMode) {
      setEvents(props.events);
    } else {
      setEvents(props.events?.filter((event) => event.active));
    }
  }, [isEditor, editorMode]);

  return (
    <header className="bg-white">
      <div className="container">
        <div className="row">
          <div className="col-10 col-md-6 col-lg-6 padding-top-0_5 padding-bottom-0_5">
            <a href="/">
              <img src={logo} className="logoImg" alt="Schule Wirtschaft" />
            </a>
          </div>
          <div className="application-bar__meta-menu">
            <div className="meta-menu js-meta-menu">
              <ul className="bare-list meta-menu__list">
                {user && isEditor && (
                  <li className="meta-menu__item">
                    <div className="toggle-switch ">
                      <input
                        id="toggle-switch-default"
                        type="checkbox"
                        defaultChecked={editorMode}
                        name="toggle-switch-default"
                        className="toggle-switch__checkbox"
                        onChange={toggleEditorMode}
                      />
                      <label
                        htmlFor="toggle-switch-default"
                        className="toggle-switch__label font-size-small"
                      >
                        Editor mode
                      </label>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={`navigation-bar ${isopen ? 'is-open' : ''}`}>
        <div className="navigation-bar__container">
          <div className="navigation-bar__panel">
            <div className="navigation-bar__wrapper">
              <button
                className="navigation-bar__toggle-button"
                onClick={() => setIsOpen(!isopen)}
                aria-label="Toggle menu"
                aria-expanded={isopen}
              >
                <div className="burger">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                Menü
              </button>
              {/* <div className="navigation-bar__app-identifier">BDWE Akademie</div> */}
            </div>
          </div>
          <ul className="bare-list navigation-bar__list">
            <li className="navigation-bar__item">
              <NavLink
                exact
                to="/"
                className="navigation-bar__link"
                activeClassName="is-active"
                onClick={() => setIsOpen(!isopen)}
              >
                Startseite
              </NavLink>
            </li>
            {events &&
              events.map((event) => (
                <li key={event.id} className="navigation-bar__item">
                  <NavLink
                    exact
                    to={`/${event.type === SessionType.SINGLE ? 'event' : 'conference'}/${event.slug
                      }`}
                    className="navigation-bar__link"
                    activeClassName="is-active"
                    onClick={() => setIsOpen(!isopen)}
                  >
                    {event.navigation_title || event.title || event.header_title}
                  </NavLink>
                </li>
              ))}
            {hasUpcoming && (
              <li className="navigation-bar__item">
                <NavLink
                  exact
                  to="/upcoming"
                  className="navigation-bar__link"
                  activeClassName="is-active"
                  onClick={() => setIsOpen(!isopen)}
                >
                  Kommende Veranstaltungen
                </NavLink>
              </li>
            )}
            <li className="navigation-bar__item">
              <NavLink
                exact
                to="/past"
                className="navigation-bar__link"
                activeClassName="is-active"
                onClick={() => setIsOpen(!isopen)}
              >
                Bisherige Veranstaltungen
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
