import React from 'react';
import ISpeaker from '../interfaces/speaker.interface';

interface Props {
  speaker: ISpeaker;
}

const SpeakerCard: React.FC<Props> = (props) => {
  const { id, image, first_name, last_name, title, job_title } = props.speaker;

  return (
    <div key={id} className="card card--rimless">
      <div className="card__image speaker__img">
        <div className="aspect-ratio-1x1 aspect-ratio--cover">
          <img alt="Description" src={image?.data.full_url} />
        </div>
      </div>
      <div className="card__body">
        <p className="font-weight-bold margin-bottom-0">{title} {first_name} {last_name}</p>
        {job_title && <small className="font-size-small">{job_title}</small>}
      </div>
    </div>
  );
};

export default SpeakerCard;
