import React, { useEffect, useState } from 'react';
import { Moment } from 'moment-timezone';
import classNames from 'classnames';
import IAlert from '../interfaces/alert.interface';
import AlertService from '../services/alerts.service';
import TimeService from '../services/time.service';

/**
 * Set alert in localStorage
 * @param alert
 */
function setAlertInLocalStorage(alert: IAlert): void {
  const alertToSave: IAlert = {
    ...alert,
    created_on: (alert.created_on as Moment).format('YYYY-MM-DD HH:mm:ss'),
    modified_on: (alert.modified_on as Moment).format('YYYY-MM-DD HH:mm:ss'),
  };

  localStorage.setItem('alert', JSON.stringify(alertToSave));
}

/**
 * Retrive alert from localStorage
 */
function getAlertFromLocalStorage(): IAlert {
  const savedAlert = JSON.parse(localStorage.getItem('alert')) as IAlert;

  if (savedAlert) {
    return {
      ...savedAlert,
      created_on: TimeService.create(savedAlert.created_on),
      modified_on: TimeService.create(savedAlert.modified_on),
    };
  }

  return null;
}

/**
 * Check if the alert has been modified
 * @param alert
 */
function hasChanged(alert: IAlert): boolean {
  const savedAlert = getAlertFromLocalStorage();

  if (!savedAlert) {
    return true;
  }

  if (alert.modified_on.valueOf() !== savedAlert.modified_on.valueOf()) {
    return true;
  }

  return false;
}

const Alert: React.FC<[]> = () => {
  const [alert, setAlert] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  function renderAlert(alert: IAlert) {
    const savedAlert = getAlertFromLocalStorage();

    // If alert has changed show it and update it in the localStorage
    if (hasChanged(alert) && alert.active) {
      setIsOpen(alert.active);
      setAlertInLocalStorage(alert);
      // If it hasn't changed but is hasn't being seen, show it
    } else if (savedAlert && !savedAlert.isSeen) {
      setIsOpen(savedAlert.active);
    }
  }

  useEffect(() => {
    const getAlert = async (callback = (alert: IAlert) => {}) => {
      const alert = await AlertService.getAlert();

      if (alert.error) {
        console.error(alert.error.message);
        setAlert({});
        return;
      }

      setAlert(alert);
      callback(alert);
    };

    getAlert((alert: IAlert) => {
      renderAlert(alert);
    });

    const interval = setInterval(() => {
      getAlert();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!alert) {
      return;
    }

    renderAlert(alert);
  }, [alert]);

  const close = () => {
    alert.isSeen = true;
    setAlertInLocalStorage(alert);
    setIsOpen(false);
  };

  return (
    <>
      {alert && alert.active && isOpen && (
        <div
          role="alertdialog"
          className={classNames('alert margin-top-1 margin-bottom-1', alert.additionalClasses, {
            'alert--success': alert.level === 'success',
            'alert--error': alert.level === 'error',
            'alert--warning': alert.level === 'warning',
          })}
        >
          <div className="alert__container">
            <button
              type="button"
              className="alert__close"
              data-dismiss="alert"
              aria-label="Close alert"
              onClick={() => close()}
            >
              <i aria-hidden="true" className="icon-close"></i>
            </button>
            {alert.title && (
              <header className="alert__header">
                <i aria-hidden="true" className="icon alert__indicator"></i>
                <h5 className="alert__title" id="alertTitle">
                  {alert.title}
                </h5>
              </header>
            )}
            <div className="alert__body" dangerouslySetInnerHTML={{ __html: alert.text }}></div>
            <footer className="alert__footer">
              <button type="button" className="button button--primary" onClick={() => close()}>
                OK
              </button>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
