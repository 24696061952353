import React, { useState } from 'react';
import { IComplexEventTalk } from '../interfaces/complexEvent.interface';
import Details from './Details';

const withDetails = (Component: any) => {
  const DetailsDrawer = () => {
    const [talk, setTalk] = useState<IComplexEventTalk>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openDetails = (talk: IComplexEventTalk = null): void => {
      const isOpen = !!talk;

      setTalk(talk);
      setIsOpen(isOpen);
    };

    return (
      <>
        <Component openDetails={openDetails} />
        <Details isOpen={isOpen} close={openDetails} talk={talk} />
      </>
    );
  };

  return DetailsDrawer;
};

export default withDetails;
