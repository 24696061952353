import React from 'react';

import head from '../assets/img/hero.jpg';
import fb from '../assets/icons/fb.svg';
import twitter from '../assets/icons/twitter.svg';
import mp4 from '../assets/videos/MINT.mp4';
import webm from '../assets/videos/MINT.webm';
import mkv from '../assets/videos/MINT.mkv';

interface Props {
  title: string;
  subtitle?: string;
  text?: string;
  headimg?: string;
}

const DefaultHeader: React.FC<Props> = (props) => (
  <React.Fragment>
    <header className="section row margin-0 justify-content-center">
      <div className="hero-block">
        <img src={props.headimg ? props.headimg : head} alt="head image" className="headImg"></img>
      </div>
    </header>
  </React.Fragment>
);

export default DefaultHeader;
