import axios from 'axios';
import { Moment } from 'moment-timezone';
import { ISimpleEventSession } from '../interfaces/simpleEvent.interface';
import TimeService from './time.service';
import { IComplexEventSession } from '../interfaces/complexEvent.interface';
import { SessionType } from '../enums/sessionType.enum';

interface RequesBody {
  sessionId: number;
  startDatetime: String;
  endDatetime: String;
}

class CalendarService {
  private static instance: CalendarService;
  private apiUrl: String = process.env.REACT_APP_API_URL;

  static getInstance(): CalendarService {
    if (!CalendarService.instance) {
      CalendarService.instance = new CalendarService();
    }

    return CalendarService.instance;
  }

  async getCalendarEvent(session: ISimpleEventSession | IComplexEventSession): Promise<any> {
    const body: RequesBody = {
      sessionId: session.id,
      startDatetime: TimeService.createTz(session.starts_at._i as Moment)
        .format('YYYYMMDD HHmmss')
        .replace(' ', 'T'),
      endDatetime: TimeService.createTz(session.ends_at._i as Moment)
        .format('YYYYMMDD HHmmss')
        .replace(' ', 'T'),
    };

    try {
      let path = '/custom/calendar';

      if (session.type === SessionType.SINGLE) {
        path += '/simple';
      }

      const request = await axios.post(`${this.apiUrl}${path}`, body);
      return request;
    } catch (err) {
      console.error(err);
    }

    return null;
  }
}

export default CalendarService.getInstance();
