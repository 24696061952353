import axios from 'axios';
import TimeService from './time.service';
import IAlert from '../interfaces/alert.interface';

class AlertsService {
  private static instance: AlertsService;
  private apiUrl: string = process.env.REACT_APP_API_URL;

  private config: object = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  static getInstance(): AlertsService {
    if (!AlertsService.instance) {
      AlertsService.instance = new AlertsService();
    }

    return AlertsService.instance;
  }

  async getAlert(): Promise<IAlert | any> {
    try {
      const res = await axios.get(`${this.apiUrl}/items/alert`, this.config);
      const alerts = res.data.data;

      return this.preProcessAlert(alerts[0]);
    } catch (error) {
      return {
        error: error,
      };
    }
  }

  private preProcessAlert(alert: IAlert): IAlert {
    const formatedText = alert.text?.replace(/(?:\r\n|\r|\n)/g, '<br>') || alert.text;
    return {
      ...alert,
      created_on: TimeService.create(alert.created_on),
      modified_on: TimeService.create(alert.modified_on),
      text: formatedText,
      isSeen: false,
    };
  }
}

export default AlertsService.getInstance();
