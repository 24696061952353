import React, { Component, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import moment from 'moment-timezone';
import { StickyContainer } from 'react-sticky';
import Loader from '../../Loader';
import DefaultHeader from '../../DefaultHeader';
import LiveHeader from '../../LiveHeader';
import CustomFooter from '../../CustomFooter';
import {
  IComplexEvent,
  IComplexEventSession,
  IComplexEventTalk,
  IComplexEventGroup,
} from '../../../interfaces/complexEvent.interface';
import ComplexEventsService from '../../../services/complexEvents.service';
import News from '../../News';
import TopicContainer from './components/TopicContainer';
import CustomSection from './components/CustomSection';
import SpeakerSection from './components/Speakers';
import ISpeaker from '../../../interfaces/speaker.interface';
import { EventStatus, SessionStatus } from '../../../enums/status.enum';
import { defaultTimezone } from '../../../const/defaultTimezone';

interface Props {
  match?: any;
  openDetails: () => void;
}
interface State {
  isLoading: boolean;
  event?: IComplexEvent;
  talks?: IComplexEventTalk[];
  sessions?: IComplexEventSession[];
  groups?: IComplexEventGroup[];
  topSpeakers?: ISpeaker[];
  speakers?: ISpeaker[];
  hasNews: boolean;
  links: any[];
  timers?: {
    updateInterval?: any;
  };
}

class ComplexEvent extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      talks: [],
      topSpeakers: [],
      speakers: [],
      sessions: [],
      links: [],
      hasNews: false,
    };
  }

  componentWillMount(): void {
    const params = this.props.match.params;

    if (params && params.slug) {
      this.getEventBySlug(params.slug);
    }
  }

  componentDidMount(): void {
    // this.setUpdateInterval();
    this.generateLinks();
  }

  componentWillUnmount(): void {
    // clearInterval(this.state.timers.updateInterval);
  }

  componentDidUpdate(prevProps, prevState): void {
    if (
      prevProps.match.params.slug &&
      prevProps.match.params.slug !== this.props.match.params.slug
    ) {
      if (this.state.timers) {
        clearInterval(this.state.timers.updateInterval);
      }

      this.setState({ isLoading: true });

      this.getEventBySlug(this.props.match.params.slug);
      // this.setUpdateInterval();
      this.generateLinks();
    }
  }

  private generateLinks() {
    // Build links
    const links = [];

    if (this.hasLiveSessions()) {
      links.push({
        to: 'upcoming',
        label: 'Kommende Veranstaltungen',
      });
    }

    if (this.state.hasNews) {
      links.push({
        to: 'news',
        label: 'News',
      });
    }

    (this.state.groups || []).forEach((group) => {
      links.push({
        to: group.group_id,
        label: group.nav_title || group.title,
      });
    });

    if (this.state.event?.custom_section_active) {
      links.push({
        to: 'custom-section',
        label: this.state.event?.custom_section_nav_title,
      });
    }

    links.push({
      to: 'speakers',
      label: 'Speakers',
    });

    this.setState({ links });
  }

  private setUpdateInterval(): void {
    const interval = setInterval(() => this.getTalks(this.state.event.id), 60000);

    this.setState({ timers: { updateInterval: interval } });
  }

  private async getEventBySlug(slug: string): Promise<any> {
    if (slug) {
      const event = (await ComplexEventsService.getEventBySlug(slug)) as IComplexEvent;

      if (!event) {
        console.error('No event found for the given slug');
        return;
      }

      this.setState(() => {
        return { event };
      });

      this.getTalks(event.id);
    }
  }

  private getSpeakers(): void {
    if (!this.state.talks || this.state.talks.length === 0) {
      return;
    }

    const allSpeakers = this.state.talks.reduce((speakers, talk) => {
      talk.speakers.forEach((speaker) => {
        const found = speakers.find((speakerToCompare) => speakerToCompare.id === speaker.id);
        if (!found) {
          speakers.push(speaker);
        }
      });
      return speakers;
    }, []);

    const topSpeakers = allSpeakers.filter((speaker) => speaker.is_top_speaker);
    const speakers = allSpeakers.filter((speaker) => !speaker.is_top_speaker);

    this.setState(() => {
      return { speakers, topSpeakers };
    });
  }

  private async getTalks(eventId: number): Promise<any> {
    if (eventId) {
      const talks = (await ComplexEventsService.getTalksForEvent(eventId)) as IComplexEventTalk[];
      let groups = [];

      if (!talks || talks.length === 0) {
        console.error('No talk found for the given complex event');
      } else {
        groups = talks
          .map((talk) => talk.group)
          .filter((group) => group.is_visible === true)
          .reduce((acc, current) => {
            const group = acc.find((savedGroup) => savedGroup.group_id === current.group_id);

            if (!group) {
              return [...acc, current];
            }
            return acc;
          }, []);
      }

      this.setState(() => {
        return {
          talks,
          groups,
          isLoading: false,
        };
      });

      // talks.forEach((talk) => this.getSessionsForTalk(talk.id));
      this.getSessions();

      this.getSpeakers();
      this.generateLinks();
    }
  }

  async getSessions(): Promise<void> {
    let complexEventSessions = [];

    if (this.state.event) {
      const sessions = (await ComplexEventsService.getSessionsForEvent(
        this.state.event.id
      )) as IComplexEventSession[];

      if (sessions) {
        complexEventSessions = [...complexEventSessions, ...sessions];
      }

      this.setState({ sessions: complexEventSessions });
    }
  }

  private async getSessionsForTalk(talkId: number): Promise<void> {
    if (talkId) {
      const fetchedSessions = (await ComplexEventsService.getSessionsForTalk(
        talkId
      )) as IComplexEventSession[];

      if (!fetchedSessions || fetchedSessions.length === 0) {
        console.error('No sessions found for the talk');
        return;
      }

      // Adds or updates the sessions on the state
      // if the state is empty, it adds the session
      // if the session changed based on the
      // modified_on and status properties
      // it updates the session on the state
      this.setState((prevState) => {
        if (prevState.sessions.length === 0) {
          return {
            sessions: fetchedSessions,
          };
        }

        const updatedSessions = prevState.sessions;

        fetchedSessions.forEach((fetchedSession) => {
          const prevSession = prevState.sessions.find(
            (prevSession) => prevSession.id === fetchedSession.id
          );

          if (!prevSession) {
            updatedSessions.push(fetchedSession);
            return;
          }

          const hasChanged = () => {
            return (
              prevSession.modified_on.valueOf() !== fetchedSession.modified_on.valueOf() ||
              prevSession.status !== fetchedSession.status
            );
          };

          if (hasChanged()) {
            const index = updatedSessions.findIndex((session) => session.id === prevSession.id);
            // remove old session
            updatedSessions.splice(index, 1);

            updatedSessions.push(fetchedSession);
          }
        });

        return {
          sessions: updatedSessions,
        };
      });

      this.generateLinks();
    }
  }

  private renderUpcoming() {
    if (!this.isLive()) {
      return null;
    }

    if (this.isLive() && !this.hasLiveSessions()) {
      return null;
    }

    const upcomingSessions = this.state.sessions
      ?.filter((session) => session.status === SessionStatus.BEFORE)
      .filter((session) => {
        const nextFourHours = moment().tz(defaultTimezone).add(4, 'hours');
        return session.starts_at.isBefore(nextFourHours);
      });

    if (upcomingSessions.length < 1) {
      return null;
    }

    return (
      <Element name="upcoming">
        <TopicContainer
          title="Kommende Veranstaltungen"
          hideMainEvent={true}
          sessions={upcomingSessions}
          openDetails={this.props.openDetails}
        />
      </Element>
    );
  }

  private renderGroups() {
    if (this.state.talks && this.state.groups) {
      return this.state.groups
        .sort((a, b) => a.group_id.localeCompare(b.group_id))
        .map((group, i) => {
          const talks = this.state.talks.filter((talk) => talk.group.group_id === group.group_id);
          const sessions = this.state.sessions.filter(
            (session) => session.talk.group.group_id === group.group_id
          );

          return (
            <Element name={group.group_id} key={group.group_id}>
              <TopicContainer
                key={`topic-container-${i}`}
                title={group.title}
                talks={talks}
                sessions={sessions}
                bgColor={i === 0 || i % 2 === 0 ? 'bg-color-gray-tint-5' : ''}
                openDetails={this.props.openDetails}
              />
            </Element>
          );
        });
    }
  }

  private updateSessionStatus(session: IComplexEventSession): IComplexEventSession {
    return {
      ...session,
      status: ComplexEventsService.setSessionStatus(session.starts_at, session.ends_at),
    };
  }

  private refreshSessions() {
    if (this.state.talks?.length > 0) {
      // this.state.talks.forEach((talk) => this.getSessionsForTalk(talk.id));
      this.setState((prevState: State) => {
        return {
          ...prevState,
          sessions: prevState.sessions.map((session) => this.updateSessionStatus(session)),
        };
      });
    }
  }

  private hasNews(hasNews: boolean): void {
    this.setState({ hasNews }, () => this.generateLinks());
  }

  private isLive(): boolean {
    if (this.state.event) {
      return this.state.event.status === EventStatus.LIVE;
    }
  }

  private isPast(): boolean {
    if (this.state.event) {
      return this.state.event.status === EventStatus.PAST;
    }
  }

  private hasLiveSessions(): boolean {
    return !!this.state.sessions?.find(
      (session) =>
        session.status === SessionStatus.LIVE || session.status === SessionStatus.SOON_LIVE
    );
  }

  private renderDefaultHeader(): ReactElement {
    let title = this.state.event.pre_header_title;
    let subtitle = '';
    let description = this.state.event.pre_header_description;

    if (this.isPast()) {
      title = this.state.event.post_header_title;
      subtitle = this.state.event.post_header_subtitle;
      description = this.state.event.post_header_description;
    }

    return (
      <DefaultHeader
        title={title}
        subtitle={subtitle}
        text={description}
        headimg={this.state.event.headimg?.data.full_url}
      />
    );
  }

  private renderLiveHeader(): ReactElement {
    let title = this.state.event.pre_header_title;
    let subtitle = '';
    let description = this.state.event.pre_header_description;

    if (this.isPast()) {
      title = this.state.event.post_header_title;
      subtitle = this.state.event.post_header_subtitle;
      description = this.state.event.post_header_description;
    }

    return (
      <>
        <DefaultHeader
          title={title}
          subtitle={subtitle}
          text={description}
          headimg={this.state.event.headimg?.data.full_url}
        />
        <LiveHeader
          title={this.state.event.live_header_title}
          sessions={this.state.sessions}
          refreshSessions={this.refreshSessions.bind(this)}
          openDetails={this.props.openDetails}
        />
      </>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading && <Loader />}
        {this.state.event && !this.state.isLoading && (
          <div>
            {this.isLive() && this.state.sessions
              ? this.renderLiveHeader()
              : this.renderDefaultHeader()}

            <StickyContainer>
              {/* <NavigationTabs links={this.state.links} /> */}

              {this.renderUpcoming()}

              <Element name="news" className="news">
                <News event={this.state.event} isRendered={this.hasNews.bind(this)} />
              </Element>

              {this.renderGroups()}

              <Element name="custom-section">
                <CustomSection
                  bgColor="bg-color-white"
                  active={this.state.event.custom_section_active}
                  title={this.state.event.custom_section_title}
                  content={this.state.event.custom_section_content}
                />
              </Element>

              {(this.state.speakers || this.state.topSpeakers) && (
                <Element name="speakers" className="speakers">
                  {this.state.topSpeakers.length !== 0 && (
                    <SpeakerSection
                      speakerLabel={this.state.event.top_speaker_label}
                      speakers={this.state.topSpeakers}
                    />
                  )}
                  {this.state.speakers && (
                    <SpeakerSection
                      speakerLabel={this.state.event.speaker_label}
                      speakers={this.state.speakers}
                    />
                  )}
                </Element>
              )}
            </StickyContainer>
            {/* CUSTOM FOOTER */}
            {this.state.event.custom_footer && (
              <CustomFooter customFooter={this.state.event.custom_footer} />
            )}
          </div>
        )}
      </>
    );
  }
}

export default withRouter(ComplexEvent);
