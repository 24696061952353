import React, { useState, useEffect } from 'react';
import DefaultHeader from '../DefaultHeader';
import EventCard from '../EventCard';
import SimpleEventsService from '../../services/simpleEvents.service';
import ComplexEventsService from '../../services/complexEvents.service';
import { useUser } from '../UserProvider';

interface Props {}

const PastEvents: React.FC<Props> = (props) => {
  const { user, editorMode } = useUser();

  // getPast
  const [pastEvents, setPastEvents] = useState(null);

  useEffect(() => {
    getPastEvents();
  }, []);

  useEffect(() => {
    getPastEvents(editorMode, user.email);
  }, [user, editorMode]);

  const getPastEvents = async (all?, email?) => {
    const simpleEvents = await SimpleEventsService.getPastEvents(all, email);
    const complexEvents = await ComplexEventsService.getPastEvents(all, email);

    const unsortedEvents = [...(simpleEvents || []), ...(complexEvents || [])];

    const events = unsortedEvents.sort(
      (a, b) => b.start_date.format('YYYYMMDD') - a.start_date.format('YYYYMMDD')
    );

    setPastEvents(events);
  };

  return (
    <div>
      <DefaultHeader title={'Bisherige Veranstaltungen'} />

      <div className="section">
        <div className="container">
          <div className="row">
            {pastEvents &&
              pastEvents.map((event) => (
                <div key={event.slug} className="col-12 col-md-6 col-lg-4">
                  <EventCard event={event} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastEvents;
