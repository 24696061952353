import React from 'react';
import { Redirect } from 'react-router-dom';

export default function Error(props) {
  // If the state of the redirect has the 'hasError' property
  // it means the user was redirected automatically from the Home component
  // thus we show the error message
  if (props.location.state && props.location.state.hasError) {
    return (
      <div className="container">
        <h1 className="color-orange">Whoops!</h1>
        <h5>There was an error loading the page.</h5>
        <h5 className="margin-bottom-2">Please reload or try again later.</h5>
        <p>
          <button
            className="button button--primary icon-reload margin-bottom-2"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </p>
      </div>
    );
  }

  // If the user reloads the browser on the /error page,
  // we redirect the user to the / page so the requests are retried
  return <Redirect to="/" />;
}
