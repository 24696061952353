import axios from 'axios';
import TimeService from './time.service';
import INews from '../interfaces/news.interface';

class NewsService {
  private static instance: NewsService;
  private apiUrl: String = process.env.REACT_APP_API_URL;

  private config: object = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  static getInstance(): NewsService {
    if (!NewsService.instance) {
      NewsService.instance = new NewsService();
    }

    return NewsService.instance;
  }

  async getNews(): Promise<INews | any> {
    try {
      const response = await axios.get(`${this.apiUrl}/items/news?fields=*.*`, this.config);

      return response.data.data.map((news: INews) => {
        return {
          ...news,
          date: TimeService.createTz(news.created_on).local(),
        };
      });
    } catch (err) {
      console.error(err);
    }

    return null;
  }
}

export default NewsService.getInstance();
