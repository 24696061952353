import React, { useEffect, useState } from 'react';
import NewsService from '../services/news.service';
import { IComplexEvent } from '../interfaces/complexEvent.interface';
import INews from '../interfaces/news.interface';

interface Props {
  showOnHome?: boolean;
  event?: IComplexEvent | any;
  isRendered?: (rendered: boolean) => void;
}

const defaultProps: Props = {
  showOnHome: false,
  isRendered: (rendered: boolean): void => {},
};

const News: React.FC<Props> = (props: Props) => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    let interval;

    const getNews = async () => {
      const news = await NewsService.getNews();

      if (!news) {
        return;
      }

      renderNews(news);
    };

    getNews();

    interval = setInterval(() => {
      getNews();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderNews = (news) => {
    // Sort and filter news by active
    let newsToShow = news
      .filter((_new) => _new.active)
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());

    // Sort news by event
    if (props.event) {
      newsToShow = newsToShow.filter((_new: INews) => _new.conference?.id === props.event?.id);
      setNews(newsToShow);
      props.isRendered(newsToShow.length > 0);
      return;
    }

    // Sort news by showOnHome
    if (props.showOnHome) {
      newsToShow = newsToShow.filter((_new: INews) => _new.show_on_home === props.showOnHome);
      setNews(newsToShow);
      props.isRendered(newsToShow.length > 0);
      return;
    }

    props.isRendered(false);
  };

  return (
    <>
      {news && news.length > 0 && (
        <div className="container">
          <h3 className="margin-0">Neuigkeiten</h3>
          <ul className="collection border-none">
            {news.map((_new, i) => (
              <li
                key={`new-${i}`}
                className="collection__item col-12 col-md-12 col-lg-12 col-xl-9 padding-left-0"
              >
                <h5 className="h5">{_new.title}</h5>
                <div>
                  <span className="label">{_new.date.format('DD.MM.YYYY')}</span>
                </div>
                <div
                  style={{ marginTop: '2px' }}
                  className="wysiwyg"
                  dangerouslySetInnerHTML={{ __html: _new.text }}
                ></div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

News.defaultProps = defaultProps;

export default News;
