import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import Topbar from './components/layout/Topbar';
import Home from './components/pages/home/Home';
import PastEvents from './components/pages/PastEvents';
import UpcomingEvents from './components/pages/UpcomingEvents';
import Error from './components/pages/Error';
import Footer from './components/layout/Footer';
import SimpleEvent from './components/pages/simple-event/SimpleEvent';
import ComplexEvent from './components/pages/complex-event/ComplexEvent';
import SimpleEventsService from './services/simpleEvents.service';
import ComplexEventsService from './services/complexEvents.service';
import { UserProvider } from './components/UserProvider';
import { IEventCard } from './interfaces/eventCard.interface';
import { EventStatus } from './enums/status.enum';
import Alert from './components/Alert';
import withDetails from './components/withDetails';
interface State {
  events?: IEventCard[];
}

class App extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getEvents();
  }

  async getEvents(): Promise<any> {
    const simpleEvents = await SimpleEventsService.getEvents();
    const complexEvents = await ComplexEventsService.getEvents();
    const events = simpleEvents.concat(complexEvents);

    if (!events) {
      return;
    }

    this.setState({ events });
  }

  render() {
    return (
      <UserProvider>
        <Router>
          <div className="App">
            <Topbar
              events={
                this.state.events
                  ? this.state.events.filter(
                    (event) =>
                      event.status === EventStatus.ONGOING || event.status === EventStatus.LIVE
                  )
                  : null
              }
            />
            <div className="container">
              <Alert />
            </div>
            <Switch>
              <Route exact path="/error" component={Error} />
              <Route exact path="/past" component={PastEvents} />
              <Route exact path="/upcoming" component={UpcomingEvents} />
              <Route exact path="/event/:slug" component={SimpleEvent} />
              <Route exact path="/conference/:slug" component={withDetails(ComplexEvent)} />
              <Route path="/" component={withDetails(Home)} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </UserProvider>
    );
  }
}

export default App;
