export default function objectToArray(obj: any): any[] {
  if (!obj || typeof obj !== 'object') {
    return null;
  }

  return Object.keys(obj).map((key) => (
    {
      'description': key,
      'url': obj[key]
    }
  ));
}