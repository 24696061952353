import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  ReactElement,
  ReactChildren,
} from 'react';
import authService from '../services/auth.service';

type Props = {
  children: ReactChildren;
};

type User = {
  firstname?: string;
  lastname?: string;
  email?: string;
};

type UserContextType = {
  user?: User;
  isEditor?: boolean;
  editorMode?: boolean;
  setEditorMode?: Dispatch<SetStateAction<boolean>>;
};

export const UserProvider = ({ children }: Props): ReactElement => {
  const [user, setUser] = useState({});
  const [isEditor, setIsEditor] = useState(false);
  const [editorMode, setEditorMode] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  /**
   * Get user
   */
  const getUser = async () => {
    const responseUser = await authService.getUserData();
    if (!responseUser) {
      return;
    }

    setUser(responseUser);
    getIsEditor(responseUser);
  };

  /**
   * Get isEditor
   */
  const getIsEditor = async (userParam) => {
    const tmpuser = userParam || user;
    const response = await authService.isEditor(tmpuser.email);

    if (!response) {
      return;
    }

    setIsEditor(response.iseditor);
  };

  return (
    <UserContext.Provider value={{ user, isEditor, editorMode, setEditorMode }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserContext = React.createContext<UserContextType>({} as UserContextType);
export const useUser = () => useContext(UserContext);
