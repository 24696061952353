import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import { ISimpleEventSession } from '../interfaces/simpleEvent.interface';
import { IComplexEventSession } from '../interfaces/complexEvent.interface';
import { SessionStatus } from '../enums/status.enum';
import { SessionType } from '../enums/sessionType.enum';
import ProgressBar from './ProgressBar';
import SessionCard from './SessionCard';
import SimpleSessionCard from './SimpleSessionCard';

interface Props {
  title: string;
  subtitle?: string;
  sessions: ISimpleEventSession[] | IComplexEventSession[] | any[];
  // Method to be invoked when the session with the progressbar is over
  refreshSessions: () => void;
  openDetails?: () => void;
}

const LiveHeader: React.FC<Props> = (props) => {
  const [liveSessions, setLiveSessions] = useState(null);
  const [soonLiveSessions, setSoonLiveSessions] = useState(null);
  const [upcomingSessions, setUpcomingSessions] = useState(null);
  const [progressSession, setProgressSession] = useState(null);

  const filterAndSort = (sessions: any[], filter: SessionStatus = SessionStatus.LIVE) => {
    return sessions
      .filter((session) => session.status === filter)
      .sort((a, b) => (a.starts_at as Moment).valueOf() - (b.starts_at as Moment).valueOf());
  };

  useEffect(() => {
    if (props.sessions) {
      setLiveSessions(filterAndSort(props.sessions as any[], SessionStatus.LIVE));

      setSoonLiveSessions(filterAndSort(props.sessions as any[], SessionStatus.SOON_LIVE));

      setUpcomingSessions(filterAndSort(props.sessions as any[], SessionStatus.BEFORE));
    }
  }, [props]);

  useEffect(() => {
    if (liveSessions && liveSessions.length > 0) {
      setProgressSession(liveSessions[0]);
    } else {
      setProgressSession(null);
    }

    if (soonLiveSessions && soonLiveSessions.length > 0) {
      setProgressSession(soonLiveSessions[0]);
    }
  }, [soonLiveSessions, liveSessions]);

  const renderSessionCard = (
    session: ISimpleEventSession | IComplexEventSession,
    isLive = false
  ) => {
    // SimpleSessionCard
    if (session.type === SessionType.SINGLE) {
      return (
        <SimpleSessionCard
          live={isLive}
          canNavigate={true}
          session={session as ISimpleEventSession}
        />
      );
    }

    // ComplexSessionCard
    if (session.type === SessionType.COMPLEX) {
      return (
        // TODO: Complex event session card
        <SessionCard
          live={isLive}
          session={session as IComplexEventSession}
          openDetails={props.openDetails}
        />
      );
    }
  };

  const hasUpcoming = () => {
    return (
      upcomingSessions &&
      upcomingSessions.length > 0 &&
      soonLiveSessions.length === 0 &&
      liveSessions.length === 0
    );
  };

  const renderUpcoming = () => {
    let lastSession = null;

    if (progressSession) {
      setProgressSession(null);
    }

    // Sort the sessions and filter the finished ones
    const previewUpcomingSessions = upcomingSessions
      .map((session, i) => {
        if (i <= 3) {
          lastSession = session;
          return session;
        }

        if (lastSession.starts_at.valueOf() === session.starts_at.valueOf()) {
          return session;
        }

        return null;
      })
      .filter((session) => session !== null);

    return (
      <>
        <h4 className="color-white margin-top-0 padding-top-1">Kommende Veranstaltungen</h4>
        <div className="row header--cards">
          {previewUpcomingSessions.map((session, i) => (
            <div
              key={`upcoming-${session.event.id}-${i}`}
              className="col-12 col-md-6 col-lg-4 col-xl-3"
            >
              {renderSessionCard(session, false)}
            </div>
          ))}
        </div>
      </>
    );
  };

  const didFinishProgress = () => {
    // Refresh all sessions
    props.refreshSessions();
  };

  return (
    <header className="section">
      <div className="hero-block bg-color-black color-white">
        <div className="container">
          {hasUpcoming() ? (
            renderUpcoming()
          ) : (
            <>
              {
                // Progressbar
                progressSession && (
                  <ProgressBar
                    session={progressSession}
                    didFinishProgress={didFinishProgress.bind(this)}
                  />
                )
              }
              <div className="row header--cards">
                {
                  // Live sessions
                  liveSessions &&
                    liveSessions.length > 0 &&
                    liveSessions.map((session, i) => (
                      <div
                        key={`live-${session.event.id}-${i}`}
                        className="col-12 col-md-6 col-lg-4 col-xl-3"
                      >
                        {renderSessionCard(session, true)}
                      </div>
                    ))
                }
                {
                  // Soon live sessions
                  soonLiveSessions &&
                    soonLiveSessions.length > 0 &&
                    soonLiveSessions.map((session, i) => (
                      <div
                        key={`soon-${session.event.id}-${i}`}
                        className="col-12 col-md-6 col-lg-4 col-xl-3"
                      >
                        {renderSessionCard(session, false)}
                      </div>
                    ))
                }
              </div>
            </>
          )}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="hero-block__title">{props.title}</h1>
            {props.subtitle && <h5 className="hero-block__subtitle">{props.subtitle}</h5>}
          </div>
        </div>
      </div>
    </header>
  );
};

export default LiveHeader;
