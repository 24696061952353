import axios from 'axios';

class AuthService {
  private static instance: AuthService;
  private userApiUrl = './auth.php';
  private editorApiUrl: string = process.env.REACT_APP_API_URL;

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  async getUserData(): Promise<void> {
    try {
      const response = await axios.get(`${this.userApiUrl}`);
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async getEditorData(email: string): Promise<void> {
    try {
      const response = await axios.get(`${this.editorApiUrl}/custom/editor?email=${email}`);
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async isEditor(email: string): Promise<void> {
    try {
      const response = await axios.get(
        `${this.editorApiUrl}/custom/editor/iseditor?email=${email}`
      );
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export default AuthService.getInstance();
