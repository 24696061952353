export enum EventStatus {
  UPCOMING = 'upcoming',
  ONGOING = 'ongoing',
  LIVE = 'live',
  PAST = 'past',
}

export enum SessionStatus {
  BEFORE = 'before',
  SOON_LIVE = 'soon_live',
  LIVE = 'live',
  AFTER = 'after',
}
