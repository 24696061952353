import React from 'react';

interface Props {
  title: string;
  titleColor?: string;
  labelColor?: string;
  extraClasses?: string;
}

const Label: React.FC<Props> = (props) => {
  if (props.title) {
    return (
      <span
        className={`label ${props.extraClasses ? props.extraClasses : ''}`}
        style={{
          backgroundColor: props.labelColor && props.labelColor,
          color: props.titleColor && props.titleColor,
        }}
      >
        {props.title}
      </span>
    );
  }
  return null;
};

export default Label;
