import axios from 'axios';
import objectToArray from '../utils/objectToArray';
import IFooter from '../interfaces/footer.interface';

class FooterService {
  private static instance: FooterService;
  private apiUrl: string = process.env.REACT_APP_API_URL;

  private config: object = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  static getInstance(): FooterService {
    if (!FooterService.instance) {
      FooterService.instance = new FooterService();
    }

    return FooterService.instance;
  }

  async getContent(): Promise<void | IFooter> {
    try {
      const response = await axios.get(`${this.apiUrl}/items/footer`, this.config);

      return this.preprocessFooter(response.data.data[0]);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  private preprocessFooter(footer) {
    return {
      ...footer,
      first_column_links: objectToArray(footer.first_column_links),
      second_column_links: objectToArray(footer.second_column_links),
    };
  }
}

export default FooterService.getInstance();
