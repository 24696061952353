import React from 'react';

interface Props {
  customFooter?: any;
}

const createMarkup = function (html) {
  return { __html: html };
};

const CustomFooter: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <section className="custom-footer bg-blue color-white">
        <div className="container padding-top-2 padding-bottom-0">
          <div className="row margin-left-0_5">
            {props.customFooter &&
              Object.keys(props.customFooter).map((customKey, i) => (
                <div key={`${customKey}-${i}`} className="col col-auto padding-0 margin-right-2">
                  <div className="margin-bottom-0_25 font-weight-bold">{customKey}</div>
                  <div dangerouslySetInnerHTML={createMarkup(props.customFooter[customKey])} />
                </div>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CustomFooter;
