import React from 'react';
import ISpeaker from '../../../../interfaces/speaker.interface';
import SpeakerCard from '../../../SpeakerCard';

interface Props {
  bgColor?: string;
  speakerLabel?: string;
  speakers: ISpeaker[];
}

const SpeakerSection: React.FC<Props> = (props) => {
  return (
    <>
      {props.speakers && (
        <div className={`section ${props.bgColor ? props.bgColor : ''}`}>
          <div className="container">
            <h3 className="margin-top-0">{props.speakerLabel || 'Sprecher'}</h3>
            <div className="row">
              {props.speakers
                .sort((a, b) => {
                  const nameA = a.last_name?.toUpperCase();
                  const nameB = b.last_name?.toUpperCase();

                  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                })
                .map((speaker) => (
                  <div key={speaker.id} className="col-6 col-md-4 col-lg-3 col-xl-2">
                    <SpeakerCard speaker={speaker} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SpeakerSection;
