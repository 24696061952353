import React from 'react';

interface Props {
  active?: boolean;
  bgColor?: string;
  title: string;
  content: string;
}

const defaultProps = {
  active: false,
};

const CustomSection: React.FC<Props> = (props) => {
  return (
    <>
      {props.active && props.content && (
        <div className={`section ${props.bgColor}`}>
          <div className="container">
            <h3 className="margin-top-0 margin-bottom-1">{props.title}</h3>
            <div className="row">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: props.content }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CustomSection.defaultProps = defaultProps;
export default CustomSection;
