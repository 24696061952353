import React, { useEffect, useState } from 'react';
import crypto from 'crypto';
import SimpleEventsService from '../../../../services/simpleEvents.service';
import ComplexEventsService from '../../../../services/complexEvents.service';
import EventCard from '../../../EventCard';
import HorizontalSessionCard from '../../../HorizontalSessionCard';
import { ISimpleEvent } from '../../../../interfaces/simpleEvent.interface';
import { useUser } from '../../../UserProvider';

interface Props {
  noEventsDescription: string;
}

const UpcomingEvents: React.FC<Props> = ({ noEventsDescription }) => {
  const { user, editorMode } = useUser();
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const getUpcomingEvents = async () => {
    const simpleEvents = (await SimpleEventsService.getUpcomingEvents(
      editorMode,
      user.email
    )) as ISimpleEvent[];
    const complexEvents = (await ComplexEventsService.getUpcomingEvents(
      editorMode,
      user.email
    )) as ISimpleEvent[];

    const events = [...(simpleEvents || []), ...(complexEvents || [])];

    setUpcomingEvents(events);
  };

  const getOngoingEvents = async () => {
    const simpleEvents = (await SimpleEventsService.getOngoingEvents(
      editorMode,
      user.email
    )) as ISimpleEvent[];
    const complexEvents = (await ComplexEventsService.getOngoingEvents(
      editorMode,
      user.email
    )) as ISimpleEvent[];

    const events = [...(simpleEvents || []), ...(complexEvents || [])];

    setOngoingEvents(events);
  };

  useEffect(() => {
    getOngoingEvents();
    getUpcomingEvents();
  }, [user, editorMode]);

  return (
    <>
      {(ongoingEvents && ongoingEvents.length > 0) ||
      (upcomingEvents && upcomingEvents.length > 0) ? (
        <div className="section">
          <div className="container">
            <h3 className="margin-top-0 margin-bottom-1">Kommende Veranstaltungen</h3>
            <div className="row margin-bottom-1">
              {(ongoingEvents || []).concat(upcomingEvents || []).map((event) => (
                <div key={event.id || crypto.randomBytes(20).toString('hex')} className="col-lg-4">
                  <EventCard event={event} />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="wysiwyg margin-bottom-2">
            <p dangerouslySetInnerHTML={{ __html: noEventsDescription }} />
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingEvents;
