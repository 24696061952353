import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ComplexEventsService from '../services/complexEvents.service';
import Label from './Label';
import CalendarDownload from './CalendarDownload';
import ViewFiles from './ViewFiles';
import { SessionStatus } from '../enums/status.enum';
import { IComplexEventTalk, IComplexEventSession } from '../interfaces/complexEvent.interface';
import DetailSpeakers from './DetailSpeakers';
import TimeService from './../services/time.service';

interface Props {
  talk: IComplexEventTalk;
  isOpen: boolean;
  close: Function;
}

const Details: React.FC<Props> = (props) => {
  const talk = props.talk;
  const [sessions, setSessions] = useState([]);
  const [liveSession, setLiveSession] = useState(null);
  const now = TimeService.nowTz();

  // Disable scroll in the body and add background
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const background = document.querySelector('.details-background');
    const overflow = props.isOpen ? 'hidden' : '';
    const display = props.isOpen ? 'block' : 'none';
    body.style.overflow = overflow;
    background.style.display = display;
  });

  // create sessions array for talk
  useEffect(() => {
    async function getSessions(talk) {
      if (talk) {
        const sessions = (await ComplexEventsService.getSessionsForTalk(
          talk.id
        )) as IComplexEventSession[];

        if (sessions.length > 0) {
          setSessions(sessions);
        }
      }
    }
    getSessions(talk);
  }, [talk]);

  useEffect(() => {
    if (sessions.length > 0) {
      setLiveSession(
        sessions.find(
          (session) =>
            session.status === SessionStatus.LIVE || session.status === SessionStatus.SOON_LIVE
        )
      );
    }
  }, [sessions]);

  // is session over
  const sessionOver = (session = null) => {
    if (session) {
      return session.status === SessionStatus.AFTER;
    }

    return sessions && sessions.length > 0 ? sessions[0].status === SessionStatus.AFTER : false;
  };

  // has session started yet
  const sessionStarted = (session = null) => {
    if (session) {
      return session.status === SessionStatus.LIVE || session.status === SessionStatus.AFTER;
    }

    return false;
  };

  const isRegistrationAvailable = () => {
    return !talk.disable_registration_on || now.isBefore(talk.disable_registration_on);
  };

  const showParticipateBtn = () => {
    return (
      ((liveSession && liveSession.live_url) ||
        (sessions && sessions[0] && sessions[0].live_url)) &&
      isRegistrationAvailable()
    );
  };

  return (
    <>
      <div className="details-background" onClick={() => props.close()}></div>
      <CSSTransition in={props.isOpen} timeout={250} classNames="detailsAnimation" unmountOnExit>
        <div className={`details ${props.isOpen ? 'is-open' : ''}`}>
          {talk && (
            <>
              <button
                className="button button--control button--small icon-close details-close"
                onClick={() => props.close()}
              ></button>
              <div className="details__content">
                <h3 className="details__title">{talk.title}</h3>

                <div className="row">
                  <div className="col-5">
                    <div className="media-object__image aspect-ratio-4x3 aspect-ratio--cover">
                      <img alt={talk.description_short} src={talk.image?.data.full_url} />
                    </div>
                  </div>
                  <div className="col-7"></div>
                </div>

                <div className="margin-top-0_5 margin-bottom-0_5">
                  {talk.group && talk.group.label && (
                    <Label
                      title={talk.group.label}
                      titleColor={talk.group.textColor}
                      labelColor={talk.group.color}
                    />
                  )}
                </div>

                <p>{talk.description_detailed}</p>
                {showParticipateBtn() && (
                  <button
                    className="button button--primary icon-extern attendBtn margin-right-1"
                    onClick={() =>
                      window.open(liveSession ? liveSession.live_url : sessions[0].live_url)
                    }
                  >
                    Jetzt teilnehmen
                  </button>
                )}

                {talk.registration_url && isRegistrationAvailable() && (
                  <button
                    className="button button--secondary attendBtn margin-top-1"
                    onClick={() => window.open(talk.registration_url)}
                  >
                    {talk.registration_button_label || 'Jetzt anmelden'}
                  </button>
                )}

                <h5 className="margin-bottom-1">Termine</h5>

                <div className="row">
                  {sessions &&
                    sessions.map((session) => (
                      <div key={session.id} className="col-12 margin-bottom-0_5">
                        <CalendarDownload session={session} />
                        {session.survey_url && sessionStarted(session) && (
                          <>
                            <br />
                            <a href={session.survey_url} target="_about" rel="noopener noreferrer">
                              Sagen Sie uns wie Ihnen die Veranstaltung gefallen hat.
                              <i className="icon-extern"></i>
                            </a>
                          </>
                        )}
                      </div>
                    ))}
                </div>

                {/* Downloads */}
                {sessionOver() && props.talk.downloads && (
                  <ViewFiles title="View Documents" data={props.talk.downloads} />
                )}

                {/* Recordings */}
                {props.talk.recordings && (
                  <ViewFiles title="View recordings" data={props.talk.recordings} />
                )}

                {talk.speakers && (
                  <DetailSpeakers speakers={talk.speakers} speakerlabel={talk.speaker_label} />
                )}
              </div>
            </>
          )}
        </div>
      </CSSTransition>
    </>
  );
};

export default Details;
