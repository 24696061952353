import axios from 'axios';

class StartPageService {
  private static instance: StartPageService;
  private apiUrl: String = process.env.REACT_APP_API_URL;

  private config: object = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  static getInstance(): StartPageService {
    if (!StartPageService.instance) {
      StartPageService.instance = new StartPageService();
    }

    return StartPageService.instance;
  }

  async getContent(): Promise<any> {
    try {
      const response = await axios.get(`${this.apiUrl}/items/start_page`, this.config);

      return response.data.data[0];
    } catch (err) {
      console.error(err);
    }

    return null;
  }
}

export default StartPageService.getInstance();
