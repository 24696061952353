import { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { defaultTimezone } from '../const/defaultTimezone';

class TimeService {
  private static instance: TimeService;

  static getInstance(): TimeService {
    if (!TimeService.instance) {
      TimeService.instance = new TimeService();
    }

    return TimeService.instance;
  }

  /**
 		Returns a timestamp from now
 	*/
  now(): Moment {
    return moment();
  }

  /**
    Returns a timestamp from now with a timezone
    @param timezone
  */
  nowTz(timezone = defaultTimezone): Moment {
    return moment().tz(timezone);
  }

  /**
    Create moment object from datetime string
    @param date
    @param format
  */
  create(datetime, format = 'YYYY-MM-DD HH:mm:ss'): Moment {
    return moment(datetime, format);
  }

  /**
    Create moment object from datetime string
    @param date
    @param format
    @param timezone
  */
  createTz(datetime, format = 'YYYY-MM-DD HH:mm:ss', timezone = defaultTimezone): Moment {
    return moment.tz(datetime, format, timezone);
  }
}

export default TimeService.getInstance();
