import React, { useState, useEffect } from 'react';
import {
  IComplexEventTalk,
  IComplexEventSession,
} from '../../../../interfaces/complexEvent.interface';
import SessionCard from '../../../SessionCard';
import Label from '../../../Label';

interface Props {
  title: string;
  talks?: IComplexEventTalk[];
  sessions?: IComplexEventSession[];
  bgColor?: string;
  hideMainEvent?: boolean;
  hasBorder?: boolean;
  openDetails: Function;
}

const defaultProps = {
  hideMainEvent: false,
  hasBorder: false,
};

const TopicContainer: React.FC<Props> = (props) => {
  const isSession = props.sessions ? true : false;
  const [mainTalk, setMainTalk] = useState(null);

  useEffect(() => {
    if (props.hideMainEvent) {
      return;
    }

    const mainTalk = props.talks.find((talk) => talk.is_main);

    if (mainTalk) {
      setMainTalk(mainTalk);
    }
  }, [props.talks]);

  const renderCards = () => {
    if (isSession) {
      return props.sessions
        .filter((session) => (props.hideMainEvent ? session.talk : !session.talk.is_main))
        .filter((session) => (session.talk.active ? session.talk : ''))
        .map((session) => {
          return (
            <div key={session.talk.id} className="col-12 col-md-6 col-lg-4 col-xl-3" >
              <SessionCard
                key={session.talk.id}
                session={session}
                openDetails={props.openDetails}
              />
            </div >
          );
        });
    }

    return props.talks
      .filter((talk) => (props.hideMainEvent ? talk : !talk.is_main))
      .filter((talk) => (talk.active ? talk : ''))
      .map((talk) => {
        return (
          <div key={talk.id} className="col-12 col-md-6 col-lg-4 col-xl-3">
            <SessionCard key={talk.id} talk={talk} openDetails={props.openDetails} />
          </div>
        );
      });
  };

  return (
    <div className={`section ${props.bgColor}`}>
      <div
        className="container"
        style={{ borderBottom: props.hasBorder ? '1px solid #e6e6e6' : 'none' }}
      >
        <h3 className="margin-top-0 margin-bottom-1">{props.title}</h3>
        {mainTalk && !props.hideMainEvent && (
          <div className="row margin-bottom-2">
            <div className="col-12 col-md-12 col-lg-5 col-xl-5">
              {/* eslint-disable */}
              <a style={{ cursor: 'pointer' }} onClick={() => props.openDetails(mainTalk)}>
                <div className="aspect-ratio-4x3">
                  <img alt="Topic teaser" src={mainTalk.image?.data.full_url}></img>
                </div>

                <button
                  className="button button--control sc-link icon-extern"
                  onClick={() => props.openDetails(mainTalk)}
                >
                  Open Session
                </button>
              </a>
            </div>
            <div className="col-12 col-md-12 col-lg-7 col-xl-7 margin-bottom-1">
              <h4 className="margin-top-0">{mainTalk.title}</h4>
              <div>
                {mainTalk.group && (
                  <Label
                    title={mainTalk.group.label}
                    titleColor={mainTalk.group.textColor}
                    labelColor={mainTalk.group.color}
                  />
                )}
              </div>
              <p>{mainTalk.description_short}</p>
            </div>
          </div>
        )}

        <div className="row">{renderCards()}</div>
      </div>
    </div>
  );
};

TopicContainer.defaultProps = defaultProps;
export default TopicContainer;
