import React from 'react';
import ISpeaker from '../interfaces/speaker.interface';

interface Props {
  speakers: ISpeaker[];
}

const DetailSpeakers: React.FC<Props> = (props) => {
  if (props.speakers && props.speakers.length > 0) {
    return (
      <>
        <h3>{props.speakerlabel || 'Sprecher'}</h3>
        {props.speakers.map((speaker) => (
          <div key={speaker.id} className="row margin-top-1">
            <div className="col-12 col-xs-12 col-sm-4 col-lg-4 col-xl-3">
              <div className="card card--rimless" style={{ marginBottom: '0.66667rem' }}>
                <div className="card__image speaker__img">
                  <div className="aspect-ratio-1x1 aspect-ratio--cover">
                    <img alt={speaker.last_name} src={speaker.image?.data.full_url} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-8 col-lg-8 col-xl-9">
              <h4 className="margin-top-0 margin-bottom-0_25">
                {speaker.title} {speaker.first_name} {speaker.last_name}
              </h4>
              {speaker.job_title && (
                <p className="margin-top-0 margin-bottom-0_25 font-size-xsmall">
                  {speaker.job_title}
                </p>
              )}
              {speaker.description && <p className="font-size-small">{speaker.description}</p>}
            </div>
          </div>
        ))}
      </>
    );
  }
  return null;
};

export default DetailSpeakers;
